import { compose } from 'recompose'
import Article from './presenter'
import { connect } from 'react-redux'

import * as ArticleActions from '../../actions/article'

const mapStateToProps = state => ({

});

const mapDispatchToProps = ( dispatch, ownProps ) => ({
    saveHandler: ( data ) => {
        const { value } = data;
        data.value = value.toJSON();
        dispatch( ArticleActions.saveArticle( data, ownProps ) )
    }
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)( Article )