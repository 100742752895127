import { lifecycle } from 'recompose';
import axios from 'axios';

export default () =>  lifecycle({
    loadFbLoginApi() {
        window.fbAsyncInit = function() {
          window.FB.init({
                appId      : 1887173844909443,
                cookie     : true,  // enable cookies to allow the server to access
                // the session
                xfbml      : true,  // parse social plugins on this page
                version    : 'v2.1' // use version 2.1
            });
        };
    
        //console.log("Loading fb api");
          // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    },

    fetchPost(){
        axios.get(`https://www.googleapis.com/blogger/v3/blogs/5702242311891926673/posts/${this.props.match.params.id}?key=AIzaSyBwdk_emu6Nutd2MyU15raITbweCmrsZuA`)
        .then( result => this.props.setPost( result.data ) );
    },

    componentDidMount() {
        this.fetchPost();
    },
    componentDidUpdate(){
        this.loadFbLoginApi();
    }
});
