import { compose, withState } from 'recompose';
// import { withRouter } from 'react-router-dom'
import PostContent from './presenter';
import preload from './preload';

export default compose(
  // withRouter,
  withState('post', 'setPost'),
  preload()
)(PostContent);
