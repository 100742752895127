import React from 'react'
// import LoadContactForm from '../../utils/LoadContactForm'
import ContactForm from'../contactForm'

import './index.css'

class ContactUs extends React.Component {

    componentDidMount(){
        //console.log('will mount')
        // LoadContactForm()
    }

    render(){
        return(
            <div className="contact-us">
                <div className="holder">
                    <div className="title-white">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="description-white">
                        <p>
                            We want to hear more from you. We are ready to answer your questions in relevant to our services.
                            We are happy to consult you about the job requirements and what kind of IT staffs that your company
                            will never want to miss out on them.
                        </p>
                    </div>
                    <ContactForm />
                    {/* <div id="contact-us"></div> */}
               </div>
            </div>
        )
    }
}

export default ContactUs;