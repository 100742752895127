import * as ApplyJobAction from '../actions/applyJob'

const initialState = {
    isUploadLoading: false,
    isUploaded: false
}

const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case ApplyJobAction.APPLY_LOADING : {
            return {
                ...state,
                isUploadLoading: true
            }
        }
        case ApplyJobAction.APPLY_SUCCESS : {
            return {
                ...state,
                isUploadLoading: false,
                isUploaded: true,
            }
        }
        case ApplyJobAction.APPLY_FAILURE : {
            return {
                ...state,
                isUploadLoading: false,
                isUploaded: false
            }
        }
        default: return state;
    }
}

export default uploadReducer;