import React from 'react';
import Helmet from 'react-helmet';
import JobAnnouncement from './announcement'
import JobList from'../jobList';
import ArticleList from'../BlogPostList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './index.css'

class Announcement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobId: '',
            // jobTitle: '',
            applyForm: {}
        };
        this.callFormBack = this.callFormBack.bind(this);
    }

    callFormBack = ( candidateInfo ) => {
        // this.setState({ jobId: this.props.job._id, jobTitle: this.props.job.jobTitle, applyForm: candidateInfo });
        // this.setState({ jobId: this.props.job._id, applyForm: candidateInfo });
        const applyInfo={jobId: this.props.job._id, jobTitle:this.props.job.jobTitle, applyForm: candidateInfo }
        this.props.handleApply( applyInfo );
    }

    componentDidUpdate(){
        console.log('ann props: ',this.props);
    }

    render(){
        return(
            <div className="announcement_container">
                <Helmet>
                    <title>{ this.props.job.isLoaded ? `ITJob | ${this.props.job.jobTitle }` : '' }</title>
                    <meta property="og:title" content={ `ITJob | ${this.props.job.isLoaded ? this.props.job.jobTitle : ''}` } />
                    <meta property="og:description" content='Apply Now! Send your CV to apply@itjobcambodia.com' />
                    <meta property="og:image" content={`http://itjobcambodia.com/assets/images/default-image-${ Math.floor(Math.random() * Math.floor(2)) }.png`} />
                </Helmet>
                {
                    this.props.upload.isUploadLoading ? 
                    <div id="overlay">
                        <h3 id="text" style={{ fontWeight: "600", color:"rgba(192, 32, 45, 0.904)", textAlign:"center", fontSize: "5em"}}>
                            <FontAwesomeIcon icon={ faSpinner } spin />
                        </h3>
                    </div>
                    :''
                }
                <div className="row">
                    <div className="col-sm-8">
                        <div className="job">
                            <div className="job-announce">
                                <h1 style={{ fontWeight: "600" }}>{ this.props.job.isLoaded ? this.props.job.jobTitle : <FontAwesomeIcon icon={ faSpinner } spin /> }</h1>
                            </div>
                            <div className="announcement">
                                <JobAnnouncement job={ this.props.job } upload={this.props.upload} callFormBack={this.callFormBack} />
                            </div>
                        </div>
                        {/* <h2>Candidate details: </h2><br/>
                        <pre><h4><b>Job ID:</b>     http://localhost:3000/announcement/5e1c1b4de2d1dd77317c068b </h4></pre>
                        <pre><h4><b>Firstname:</b>  aaa </h4></pre>
                        <pre><h4><b>Lastname:</b>   bbb </h4></pre>
                        <pre><h4><b>Email:</b>      aa@email.com </h4></pre>
                        <pre><h4><b>CV_URL:</b>     https://storage.cloud.google.com/itjob_cv/1579862443995_videbee.pdf </h4></pre> */}
                    </div>
                    <div className="col-sm-4">
                        <div className="card mb-3">
                            <a className="card-header" href='/'>Recent jobs</a>
                            <JobList/>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header">Recent articles</div>
                            <ArticleList/>
                        </div>
                        <div className="mb-3" style={{ textAlign: "center" }}>
                            <img src='/assets/images/home_service.png' width='100%' alt='home-service' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Announcement;