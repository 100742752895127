import React from 'react';
import NavBar from '../../component/NavBar';
import JobEdit from '../../component/Posting';
import Footer from '../../component/Footer';

class JobEditView extends React.Component {
  render() {
    return (
      <div className="job-edit-view">
        <NavBar />
        <JobEdit />
        <Footer />
      </div>
    );
  }
}

export default JobEditView;
