import React from 'react';
//import { connect } from 'react-redux';

//import FacebookLogin from 'react-facebook-login';
import image from './images/logo-itjob.png';
import Slide from 'react-reveal/Slide';
import './index.css';
import exclamation from './images/exclamation.png';
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

var required = {
  border: '1px solid red',
  backgroundImage: `url(${exclamation})`,
  backgroundSize: '20px 20px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 10px center',
};

var normal = {
  border: '0px',
  backgroundImage: '',
};

var message = {
  position: 'absolute',
  color: 'red',
  marginLeft: '36px',
  fontSize: '13px',
  fontStyle: 'italic',
  marginTop: '-25px',
};

class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      signup: false,

      firstNameIsValid: false,
      lastNameIsValid: false,
      birthIsValid: false,
      birthNotEmpty: false,
      emailIsValidSignup: false,
      emailNotEmptySignup: false,
      passIsValidSignup: false,
      passNotEmptySignup: false,
      conPassIsValid: false,
      conPassNotEmpty: false,
      formValidationSignup: false,

      emailValueLogin: '',
      passValueLogin: '',
      emailIsValidLogin: false,
      emailNotEmptyLogin: false,
      passIsValidLogin: false,
      passNotEmptyLogin: false,
      formValidationLogin: false,
    };
  }

  fieldValidationSignup = (event) => {
    let input = event.target.name;
    let value = event.target.value;

    switch (input) {
      case 'firstname': {
        let isValidFirstName = value.length === 0 ? false : true;
        this.props.updateProfile({ ...this.props.profile, firstname: value });
        this.setState({ firstNameIsValid: isValidFirstName });
        break;
      }
      case 'lastname': {
        let isValidLastName = value.length === 0 ? false : true;
        this.props.updateProfile({ ...this.props.profile, lastname: value });
        this.setState({ lastNameIsValid: isValidLastName });
        break;
      }
      case 'birthday': {
        let isEmptyBirth = value.length === 0 ? false : true;
        this.setState({ birthNotEmpty: isEmptyBirth });

        let isValidBirth =
          value.match(/[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/) !== null
            ? true
            : false;
        this.props.updateProfile({ ...this.props.profile, birthday: value });
        this.setState({ birthIsValid: isValidBirth });
        break;
      }
      case 'email-signup': {
        let isEmptyEmail = value.length === 0 ? false : true;
        this.setState({ emailNotEmptySignup: isEmptyEmail });
        // eslint-disable-next-line
        let isValidEmail =
          value.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) !== null
            ? true
            : false;
        this.props.updateProfile({ ...this.props.profile, emailSignup: value });
        this.setState({ emailIsValidSignup: isValidEmail });
        break;
      }
      case 'password-signup': {
        let isEmptyPass = value.length === 0 ? false : true;
        this.setState({ passNotEmptySignup: isEmptyPass });

        let isValidPass =
          value.length >= 8 && value.length <= 20 ? true : false;
        this.props.updateProfile({
          ...this.props.profile,
          passwordSignup: CryptoJS.AES.encrypt(
            value,
            process.env.REACT_APP_SECRET_KEY
          ).toString(),
        });
        this.setState({ passIsValidSignup: isValidPass });
        break;
      }
      case 'confirm-password': {
        let isEmptyConPass = value.length === 0 ? false : true;
        this.setState({ conPassNotEmpty: isEmptyConPass });

        let isValidConPass =
          value ===
          CryptoJS.AES.decrypt(
            this.props.profile.passwordSignup,
            process.env.REACT_APP_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8)
            ? true
            : false;
        this.props.updateProfile({
          ...this.props.profile,
          confirmPassword: CryptoJS.AES.encrypt(
            value,
            process.env.REACT_APP_SECRET_KEY
          ).toString(),
        });
        this.setState({ conPassIsValid: isValidConPass });
        break;
      }
      default:
        break;
    }
  };

  formValidationSignup = (event) => {
    event.preventDefault();
    if (
      this.state.firstNameIsValid &&
      this.state.lastNameIsValid &&
      this.state.emailIsValidSignup &&
      this.state.passIsValidSignup &&
      this.state.conPassIsValid &&
      this.state.birthIsValid
    )
      this.props.handleSubmitSignup();
    this.setState({ formValidationSignup: true });
  };

  fieldValidationLogin = (event) => {
    let input = event.target.name;
    let value = event.target.value;

    switch (input) {
      case 'email-login': {
        let isEmptyEmailLogin = value.length === 0 ? false : true;
        this.setState({ emailNotEmptyLogin: isEmptyEmailLogin });

        let isValidEmailLogin =
          value.match(
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) !== null
            ? true
            : false;

        this.setState({
          emailValueLogin: value,
          emailIsValidLogin: isValidEmailLogin,
        });
        break;
      }
      case 'password-login': {
        let isEmptyPassLogin = value.length === 0 ? false : true;
        this.setState({ passNotEmptyLogin: isEmptyPassLogin });

        let isValidPassLogin =
          value.length >= 8 && value.length <= 20 ? true : false;
        this.setState({
          passValueLogin: CryptoJS.AES.encrypt(
            value,
            process.env.REACT_APP_SECRET_KEY
          ).toString(),
          passIsValidLogin: isValidPassLogin,
        });
        break;
      }
      default:
        break;
    }
  };

  formValidationLogin = (event) => {
    event.preventDefault();
    if (this.state.emailIsValidLogin && this.state.passIsValidLogin)
      this.props.handleSubmitLogin(
        this.state.emailValueLogin,
        this.state.passValueLogin
      );
    this.setState({ formValidationLogin: true });
  };

  render() {
    return (
      <div className="container">
        <div className="login-signup">
          <Slide left when={this.state.login}>
            <div className="login-part">
              <div className="login-container">
                <div className="header">
                  <div className="header-des">
                    <div>
                      <span
                        style={{
                          display: 'block',
                          fontSize: '24px',
                          fontWeight: 600,
                        }}
                      >
                        Login
                      </span>
                      <span>
                        Enjoy multitude of functionalities in recruitment
                      </span>
                    </div>
                  </div>
                </div>
                <div className="body">
                  {
                    //console.log(this.props)
                  }
                  <form onSubmit={this.formValidationLogin}>
                    <div className="body-des">
                      <div className="form-input-username">
                        <input
                          type="text"
                          name="email-login"
                          placeholder="Email"
                          style={
                            !this.state.formValidationLogin
                              ? normal
                              : this.state.emailIsValidLogin
                              ? normal
                              : required
                          }
                          onChange={this.fieldValidationLogin}
                        />
                        <p style={message}>
                          {!this.state.formValidationLogin
                            ? ''
                            : this.state.emailNotEmptyLogin &&
                              !this.state.emailIsValidLogin
                            ? 'Invalid E-mail format'
                            : ''}
                        </p>
                      </div>
                      <div className="form-input-password">
                        <input
                          type="password"
                          name="password-login"
                          placeholder="Password"
                          style={
                            !this.state.formValidationLogin
                              ? normal
                              : this.state.passIsValidLogin
                              ? normal
                              : required
                          }
                          onChange={this.fieldValidationLogin}
                        />
                        <p style={message}>
                          {!this.state.formValidationLogin
                            ? ''
                            : this.state.passNotEmptyLogin &&
                              !this.state.passIsValidLogin
                            ? 'Your password must be between 8 and 20 characters long.'
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className="form-input-submit">
                      {this.props.auth.loginLoading ? (
                        <span
                          className="loginSubmit"
                          style={{ overflow: 'visible' }}
                        >
                          {<FontAwesomeIcon icon={faSpinner} spin />}
                        </span>
                      ) : (
                        <input
                          className="loginSubmit"
                          type="submit"
                          value="Login"
                        />
                      )}
                    </div>
                  </form>
                  {
                    //<div style={{textAlign: 'center', marginTop: '80px'}}>
                    //    <FacebookLogin appId='1887173844909443' autoLoad={false} fields="id,first_name,last_name,email" callback={this.facebookResponse} />
                    //</div>
                  }
                </div>
              </div>
              <div className="qa-container">
                <div className="qa">
                  <div>
                    <span
                      style={{
                        color: 'rgb(173, 66, 66)',
                        fontWeight: 600,
                        fontSize: '30px',
                      }}
                    >
                      ITJob Cambodia
                    </span>
                    <img src={image} alt={'IT-Job'} className="itjob-logo" />
                  </div>
                  <div style={{ marginTop: '34px' }}>
                    <h5>Why do you need to register?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <h5>What are the currently available features?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <h5>What are the exciting upcoming features?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <h5>Do you have an account yet?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <p
                      className="signup-btn"
                      onClick={this.loginPart.bind(this)}
                    >
                      Register for FREE
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <Slide right when={this.state.signup}>
            <div className="signup-part">
              <div className="qa-container-2">
                <div className="qa">
                  <div>
                    <span
                      style={{
                        color: 'rgb(173, 66, 66)',
                        fontWeight: 600,
                        fontSize: '30px',
                      }}
                    >
                      ITJob Cambodia
                    </span>
                    <img src={image} alt={'IT-Job'} className="itjob-logo" />
                  </div>
                  <div style={{ marginTop: '34px' }}>
                    <h5>Why do you need to register?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <h5>What are the currently available features?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <h5>What are the exciting upcoming features?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <h5>Do you have an account yet?</h5>
                    <p>
                      ITJob is here to support both job seekers and companies.
                      <br />
                      We bring the right candidate to the right position at the
                      right company.
                    </p>
                    <p
                      className="login-btn"
                      onClick={this.signupPart.bind(this)}
                    >
                      Login
                    </p>
                  </div>
                </div>
              </div>
              <div className="login-container-2">
                <div className="header-2">
                  <div className="header-des">
                    <div>
                      <span
                        style={{
                          display: 'block',
                          fontSize: '24px',
                          fontWeight: 600,
                        }}
                      >
                        Sign Up
                      </span>
                      <span>
                        Enjoy multitude of functionalities in recruitment
                      </span>
                    </div>
                  </div>
                </div>
                <div className="body-2">
                  <form onSubmit={this.formValidationSignup}>
                    <div className="body-des">
                      <div className="form-input-username">
                        <input
                          type="text"
                          name="firstname"
                          className="form-fn"
                          placeholder="First Name"
                          style={
                            !this.state.formValidationSignup
                              ? normal
                              : this.state.firstNameIsValid
                              ? normal
                              : required
                          }
                          onChange={this.fieldValidationSignup}
                        />
                        <input
                          type="text"
                          name="lastname"
                          className="form-ln"
                          placeholder="Last Name"
                          style={
                            !this.state.formValidationSignup
                              ? normal
                              : this.state.lastNameIsValid
                              ? normal
                              : required
                          }
                          onChange={this.fieldValidationSignup}
                        />
                      </div>
                      <input
                        type="text"
                        name="birthday"
                        className="others-form"
                        placeholder="Date of Birth (DD/MM/YYYY)"
                        style={
                          !this.state.formValidationSignup
                            ? normal
                            : this.state.birthIsValid
                            ? normal
                            : required
                        }
                        onChange={this.fieldValidationSignup}
                      />
                      <p style={message}>
                        {!this.state.formValidationSignup
                          ? ''
                          : this.state.birthNotEmpty && !this.state.birthIsValid
                          ? 'Date of Birth should follow this form (DD/MM/YYYY)'
                          : ''}
                      </p>
                      <input
                        type="text"
                        name="email-signup"
                        className="others-form"
                        placeholder="E-mail Address"
                        style={
                          !this.state.formValidationSignup
                            ? normal
                            : this.state.emailIsValidSignup
                            ? normal
                            : required
                        }
                        onChange={this.fieldValidationSignup}
                      />
                      <p style={message}>
                        {!this.state.formValidationSignup
                          ? ''
                          : this.state.emailNotEmptySignup &&
                            !this.state.emailIsValidSignup
                          ? 'Invalid E-mail format'
                          : ''}
                      </p>
                      <input
                        type="password"
                        name="password-signup"
                        className="others-form"
                        placeholder="Password"
                        style={
                          !this.state.formValidationSignup
                            ? normal
                            : this.state.passIsValidSignup
                            ? normal
                            : required
                        }
                        onChange={this.fieldValidationSignup}
                      />
                      <p style={message}>
                        {!this.state.formValidationSignup
                          ? ''
                          : this.state.passNotEmptySignup &&
                            !this.state.passIsValidSignup
                          ? 'Your password must be between 8 and 20 characters long.'
                          : ''}
                      </p>
                      <input
                        type="password"
                        name="confirm-password"
                        className="others-form"
                        placeholder="Confirm Password"
                        style={
                          !this.state.formValidationSignup
                            ? normal
                            : this.state.conPassIsValid
                            ? normal
                            : required
                        }
                        onChange={this.fieldValidationSignup}
                      />
                      <p style={message}>
                        {!this.state.formValidationSignup
                          ? ''
                          : this.state.conPassNotEmpty &&
                            !this.state.conPassIsValid
                          ? "Your confirm password doesn't match to your entered password."
                          : ''}
                      </p>
                    </div>
                    <div className="form-input-submit">
                      {this.props.profile.isProfileLoading ? (
                        <span
                          className="submit"
                          style={{ overflow: 'visible' }}
                        >
                          {<FontAwesomeIcon icon={faSpinner} spin />}
                        </span>
                      ) : (
                        <input
                          className="submit"
                          type="submit"
                          value="Sign Up"
                        />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    );
  }

  loginPart = () => {
    this.setState({ login: false });
    this.setState({ signup: true });
    document.querySelector('.signup-part').style.zIndex = '1';
    document.querySelector('.login-part').style.display = '0';
  };
  signupPart = () => {
    this.setState({ login: true });
    this.setState({ signup: false });
    document.querySelector('.signup-part').style.zIndex = '0';
    document.querySelector('.login-part').style.display = '1';
  };
}

export default LoginView;
