import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faPencilAlt, faTrashAlt, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

class UserJobList extends React.Component {
    
    listOfJobs = () => {
        const { allJobUser } = this.props.jobUser;
        return allJobUser.map( job => 
            <tr key={ job._id } className="job-row" onClick={ () => this.props.goToEditJob( job._id )}>
                <td>
                    { 
                        !(this.state.jobSelected.indexOf(job._id) > -1 )? <FontAwesomeIcon icon= { faSquare } className="square"  onClick={ ( event ) => this.tickBox( event, job._id ) } />
                        :  <FontAwesomeIcon icon= { faCheckSquare } className="square"  onClick={ ( event ) => this.tickBox( event, job._id )} style={{ color: "black"}}/>
                    }
                    <FontAwesomeIcon icon={ faPencilAlt } className="pencil" />{ job.jobTitle }
                </td>
                <td className="user-job-industry" style={{ textAlign: "center" }}>{ job.industry ? job.industry : '-' }</td>
                <td style={{ textAlign: "center" }}>{ job.salaryFrom } - { job.salaryTo }</td>
                <td className="user-job-location" style={{ textAlign: job.location ? '' : 'center' }}>{ job.location ? job.location : '-' }</td>
            </tr>
        );   
    }

    render() {
        return (
            <div className="userjoblist_container">
                <div className="button">
                    <button className="unpublish" onClick={ () => this.props.unpublishJob( this.state ) }>Unpublish Job(s)</button>
                    <button className="delete" onClick={ () => this.props.deleteJob( this.state ) }><FontAwesomeIcon icon={ faTrashAlt } style={{ marginRight: "10px"}}/>Delete Selected Job(s)</button>
                </div>
                <div className="user-job-list">
                    <table>
                        <thead>
                            <tr>
                                <th width="40%">Job Title</th>
                                <th className="user-job-industry" width="10%">Job Industry</th>
                                <th>Salary <span className="user-job-industry">Range </span>(USD)</th>
                                <th width="10%">Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.props.jobUser.isLoaded ? this.listOfJobs():
                                <tr><td colSpan="4"><h1 style={{ fontWeight: "600", color:"rgba(192, 32, 45, 0.904)", textAlign:"center", fontSize: "5em"}}><FontAwesomeIcon icon={ faSpinner } spin /></h1></td></tr> }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    constructor( props ){
        super( props );
        this.state = {
            jobSelected: [] 
        }
    }

    tickBox = ( event, jobid ) => {
        event.stopPropagation();
        let { jobSelected } = this.state;
        if( jobSelected.indexOf(jobid) > -1 ) jobSelected.splice( jobSelected.indexOf(jobid), 1);
        else jobSelected.push( jobid );
        this.setState({ jobSelected })
    }
}

export default UserJobList;