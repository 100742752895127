import React from 'react'
import parse from 'html-react-parser';
import htmlToText from 'html-to-text';
import Gist from'react-gist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './index.css'
import Helmet from 'react-helmet';
//import ABAlogo from'../../../assets/images/ztozetb7xca1pg2fzvyj.png';
import JobList from '../jobList';
import ArticleList from '../BlogPostList';
import formateDate from '../../utils/FormateDate';
import GoogleAdsense from '../../component/GoogleAdsense';

class PostContent extends React.Component {
  render(){
    const options = {
      // replace: ({ attribs }) => {
      replace: domNode => {
        if (!domNode) return;
        if (domNode.name === 'img') {
          // console.log('img:',domNode.attribs);
          return <img className="img-fluid" src={domNode.attribs.src} alt='postThumbnail'/>;
        }
        if (domNode.name === 'script') {
          let src=domNode.attribs.src;
          let id=src.substring(src.lastIndexOf('/') + 1, src.length - 3);
          // console.log('domNode:', id);
          return <Gist id={id} />;
        }
      }
    };

    /**
     * to get the metadata information from the blogger content.
     * Limitation: the blogpost should contain one image at the top of the article
     *    that image will be the thumbnail
     */
    let html = this.props.post ? htmlToText.fromString( this.props.post.content ) : '';
    // console.log('htmltoText:',html);
    const getMetaData = {
      getThumbnail: () => html.substring( html.indexOf('[') + 1, html.indexOf(']')),
      getDescription: () => html.substring( html.indexOf(']') + 1 ).trim()
    }

    return (
      <div className='post-content'>
        {/* { console.log( getMetaData.getThumbnail(), getMetaData.getDescription() ) } */}
        <Helmet>
            <title>{ this.props.post ? `ITJob | ${this.props.post.title}` : '' }</title>
            <meta property="og:title" content={ `ITJob | ${this.props.post ? this.props.post.title : ''}` } />
            <meta property="og:image" content={ getMetaData.getThumbnail() } />
            <meta property="og:description" content={ getMetaData.getDescription() } />
        </Helmet>

        <div id="fb-root"></div>
        {
          this.props.post?
          <div>
            <div className="row">
              <div className="col-sm-8">
                {/* {console.log(this.props.post)} */}
                <h2 className='postTitle font-weight-bold'>{this.props.post.title}</h2>
                <small>{this.props.post.author.displayName}, </small>
                <small >{formateDate(this.props.post.updated)}</small>
                {/* render Facebook like button */}
                <div className='fb mt-3 mb-2'>
                  <div className="fb-like pr-2" 
                    data-href={`${process.env.REACT_APP_APP_URL}/postContent/${this.props.post.id}`} 
                    data-layout="box_count" 
                    data-action="like" 
                    data-size="small">
                  </div>  
                  {/* render Facebook share button */}
                  <div className="fb-share-button" 
                    data-href={`${process.env.REACT_APP_APP_URL}/postContent/${this.props.post.id}`} 
                    data-layout="box_count" data-size="small">
                      <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" 
                        className="fb-xfbml-parse-ignore">
                      </a>
                  </div>
                </div>
                {/* render content from blogspot */}
                <div className='article-content'>
                  { parse(this.props.post.content, options) }
                </div>
                <GoogleAdsense />
              </div>
              <div className="col-sm-4">
                <div className="card mb-3">
                    <a className="card-header" href='/'>Recent jobs</a>
                    <JobList/>
                </div>
                <div className="card mb-3">
                  <div className="card-header">Donate Us</div>
                  <div className="card-body">
                    <div className="card-text">If you think our articles help you, this article would help the other Cambodians as well. We wish Cambodian can learn without English.
                     We try to explain things in simple Khmer words.<br /><br />Join our cause by <b>DONATING US</b>. Every single cent makes the difference.</div>
                    <br />
                    <img src='/assets/images/ztozetb7xca1pg2fzvyj.png' className="float-left pr-2" alt="..." />
                    <p className="card-text">Acc. No: <b>000 961 732</b></p>
                    <p className="card-text">Acc. Holder: CHE*******TANA</p>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-header ">Like us on Facebook</div>
                  <div className="fb-page" 
                    data-href="https://www.facebook.com/ITJobCambodia/"
                    data-width="500" 
                    data-hide-cover="false"
                    data-show-facepile="false"></div>
                </div>
                <div className="card mb-3">
                  <div className="card-header">Recent articles</div>
                  <ArticleList/>
                </div>
              </div>
            </div>

            {/* render Facebook comment */}
            <div className="comment col-sm-8">
              <div className="fb-comments" 
                data-href={`${process.env.REACT_APP_APP_URL}/postContent/${this.props.post.id}`} 
                data-width="100%"
                data-numposts="5"></div>
            </div> 
          </div>
          :<h1 style={{ fontWeight: "600", color:"rgba(192, 32, 45, 0.904)", textAlign:"center", fontSize: "5em"}}>
            <FontAwesomeIcon icon={ faSpinner } spin />
          </h1>
        }
      </div>
    )
  }
}

export default PostContent;