import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import AnnouncementList from './presenter';
import { connect } from 'react-redux';
import preload from './preload';
import * as JobActions from '../../actions/job';

const mapStateToProps = (state) => ({
  allJobs: state.allJobs,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadAllJobs: () => dispatch(JobActions.loadAllJobs()),
  goToDetail: (id) => {
    ownProps.history.push('/announcement/' + id);
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  preload()
)(AnnouncementList);
