import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Posting from './presenter';
import preload from './preload';

import * as AnnouncementActions from '../../actions/announcement';
import * as JobActions from '../../actions/job';
import * as JobEditActions from '../../actions/job-edit';

const withSubmitHandler = withHandlers({
  handleSubmit: (props) => () => {
    props.saveChanges(props.announcement);
  },
});

const mapStateToProps = (state) => ({
  announcement: state.announcement,
  // jobEdit: state.jobEdit
  job: state.job,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateAnnouncement: (announcement) => {
    dispatch(AnnouncementActions.set(announcement));
  },
  saveChanges: (announcement) => {
    console.log('announcement', JSON.stringify(announcement));
    dispatch(AnnouncementActions.saveAnnouncement(ownProps));
  },
  loadJob: () => dispatch(JobActions.load(ownProps.match.params.id)),
  // jobEditLoad: () => dispatch( JobEditActions.jobEditLoadJob( ownProps.match.params.id ) ),
  updateAfterEditJob: (jobid) =>
    dispatch(JobEditActions.updateAfterEditJob(jobid, ownProps)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  preload(),
  withSubmitHandler
)(Posting);
