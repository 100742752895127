import axios from 'axios'

export const ARTICLE_SET = 'ARTICLE_SET'
export const ARTICLE_SAVE = 'ARTICLE_SAVE'
export const ARTICLE_LOADED = 'ARTICLE_LOADED'

export const load = () => ({
    type: ARTICLE_LOADED
})

export const save = () => ({
    type: ARTICLE_SAVE
});

export const saveArticle = (data, onSuccess) => {
    return (dispatch, getState) => {
        console.log( 'saveArticle', JSON.stringify(data) )
        return axios.post( process.env.REACT_APP_API_URL + 'article/new', data )
                    .then( saveArticle => {
                        console.log( saveArticle )
                        dispatch( save() )
                    } )
                    .catch( err => {
                        console.log( 'saveArticle', err )
                    } )
    }
}