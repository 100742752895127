import axios from 'axios'

export const JOB_REQUEST = 'JOB_REQUEST'
export const JOB_REQUEST_SUCCESS = 'JOB_REQUEST_SUCCESS'
export const JOBS_REQUEST_SUCCESS = 'JOBS_REQUEST_SUCCESS'
export const ALLJOBS_REQUEST_SUCCESS = 'ALLJOBS_REQUEST_SUCCESS'
export const JOB_REQUEST_LOAD = 'JOB_REQUEST_LOAD'

export const jobReqestLoad = () => ({
    type: JOB_REQUEST_LOAD
})
export const requestSuccess = ( data ) => ({
    type: JOB_REQUEST_SUCCESS,
    data
});
export const requestAllJobsSuccess = ( data ) => ( {
    type: ALLJOBS_REQUEST_SUCCESS,
    data
})
export const requestJobsSuccess = ( data ) => ({
    type: JOBS_REQUEST_SUCCESS,
    data
});

export const loadAllJobs = () => {
    return ( dispatch ) => {
        return axios.get( process.env.REACT_APP_API_URL + 'announcement/all' )
                .then( result => {
                    //console.log( 'load all jobs', result );

                    dispatch( requestJobsSuccess( result.data ) );
                } )
                .catch( error => {
                    if( error ) console.log( 'Load all jobs, Error', error )
                });
    }
}

export const loadAllJobsForUser = () => {
    return ( dispatch ) => {
        return axios.get( process.env.REACT_APP_API_URL + 'announcement/jobsForUser/' + localStorage.getItem('id'))
                .then( result => {
                    // console.log("load all job for user:", result )
                    dispatch( requestAllJobsSuccess( result.data ) );
                } )
                .catch( error => {
                    if( error ) console.log( 'Load all jobs for user error ', error)
                } )
    }
}

export const load = ( jobId ) => {
    return ( dispatch ) => {
        dispatch( jobReqestLoad() )
        // console.log( 'jobID:', jobId )
        return axios.get( process.env.REACT_APP_API_URL + 'announcement/' + jobId )
                    .then( result => {
                        // console.log('load job', result);
                        //localStorage.setItem('newAnnouncement', savedAnnouncement.data.message)
                        dispatch( requestSuccess( result.data ) )
                        //onSuccess.history.push({ pathname: '/', state: {message: savedAnnouncement.data.message}})
                    })
                    .catch( error => {
                        if(error) console.log('Error');
                    })
    }
}