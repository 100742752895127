import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import { createBrowserHistory } from 'history';
import HomepageView from './views/Homepage';
import Login from './views/Login';
import Welcome from './views/Welcome';
import UserJobList from './views/UserJobList';
import Posting from './views/Posting';
import Article from './views/Article';
import JobAnnouncement from './views/JobAnnouncement';
import ApplyJob from './views/ApplyJob';
import JobEdit from './views/JobEdit';
import BlogPosts from './views/BlogPosts';
import PostContent from './views/PostContent';

export default (
  <Router history={createBrowserHistory()}>
    <Switch>
      <ProtectedRoute path="/welcome" protectedComponent={Welcome} />
      <ProtectedRoute path="/posting" protectedComponent={Posting} />
      <ProtectedRoute path="/new/article" protectedComponent={Article} />
      <ProtectedRoute path="/user-job-list" protectedComponent={UserJobList} />
      <ProtectedRoute path="/job-edit/:id" protectedComponent={JobEdit} />

      <Route
        path="/announcement/:id"
        render={(props) => <JobAnnouncement {...props} />}
      />
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/apply" render={(props) => <ApplyJob {...props} />} />
      <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
      <Route
        path="/post-content/:id"
        render={(props) => <PostContent {...props} />}
      />
      <Route path="/" render={(props) => <HomepageView {...props} />} />
    </Switch>
  </Router>
);
