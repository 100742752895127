import React from 'react'
import NavBar from '../../component/NavBar';
import PostContent from'../../component/PostContent';
import './index.css'
import ReactGA from 'react-ga';

class PostView extends React.Component {
    
    constructor(){
        super();

        if( process.env.NODE_ENV === 'production' ){
            ReactGA.initialize('UA-35234591-4');
            ReactGA.pageview(window.location.pathname);
        }
    }

    render(){
        return (
            <div className="blogPosts">
                <NavBar />
                <PostContent />
            </div>
        )
    }

}

export default PostView;