import axios from 'axios'
import swal from 'sweetalert';

export const PROFILE_SET = 'PROFILE_SET'
export const PROFILE_SAVE = 'PROFILE_SAVE'
export const PROFILE_LOADING = 'PROFILE_LOADING'
export const PROFILE_LOAD_FAIL = 'PROFILE_LOAD_FAIL'

export const loadingFail = () => ({
    type: PROFILE_LOAD_FAIL
})

export const loading = () => ({
    type: PROFILE_LOADING
})
export const set = ( profile ) => ({
    type: PROFILE_SET,
    profile
})

export const save = () =>({
    type: PROFILE_SAVE
})

export const saveProfile = ( onSuccess ) =>{
    return (dispatch, getState) => {
        dispatch( loading() )
        // console.log( getState().profile )
        return axios.post( process.env.REACT_APP_API_URL + 'newprofile', getState().profile)
                    .then( savedProfile => {
                        // console.log('savedProfile', saveProfile)
                        localStorage.setItem('registerMessage', savedProfile.data.message)
                        if( savedProfile.data.registered ){
                            dispatch( save() )
                            swal({
                                title: "Congrats!",
                                text: "Your account is created!",
                                icon: "success",
                                closeOnClickOutside: false,
                            });
                        }else{
                            dispatch( loadingFail() )
                            swal({
                                title: "Sorry!",
                                text: "This account is already registered!",
                                icon: "error",
                                closeOnClickOutside: false,
                            });
                        }
                        onSuccess.history.push({ pathname: '/login', state: { message: savedProfile.data.message }})
                    })
                    .catch( error => {
                        if(error) console.log('Error');
                    })
    }
}