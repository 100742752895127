import React from 'react';
import image1 from './images/beverage-coffee-cup.jpg';
import image2 from './images/office-open-space.jpg';
import image3 from './images/agreement-business-businessman.jpg';

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import 'normalize.css/normalize.css';
import './slider-animations.css';
import './index.css';


const content = [
    {
        title: 'Grap a cup of coffee',
        description: 'Let\'s us bring the potential IT candidates to your office door',
        image: image1
    },
    {
        title: 'Empowering Your Company with Technology',
        description: 'The bigger your company, the more you need a robust IT to ensure everything is under control.',
        image: image2
    },
    {
        title: 'Get the Best Out of Us',
        description: 'Tell us what your company need, we will sort it out for you.',
        image: image3
    }
];

export default class SlideShow extends React.Component {
    render() {
        return (
            <Slider className='slider-wrapper' autoplay={1600} infinite touchDisabled>
                { content.map( ( item, index ) => (
                    <div
                        key= { index }
                        className='slider-content'
                        style= { { background: `url('${item.image}') no-repeat center center` } }
                    >
                        <div className='inner'>
                            <h1>{ item.title }</h1>
                            <p>{ item.description }</p>
                        </div>
                    </div>
                ) ) }
            </Slider>
        );
    }
}