import axios from 'axios'

export const ANNOUNCEMENT_SET = 'ANNOUNCEMENT_SET'
export const ANNOUNCEMENT_SAVE = 'ANNOUNCEMENT_SAVE'
export const ANNOUNCEMENT_LOAD = 'ANNOUNCEMENT_LOAD'

export const load = () => ({
    type: ANNOUNCEMENT_LOAD
})
export const set = ( announcement ) => ({
    type: ANNOUNCEMENT_SET,
    announcement
})

export const save = () => ({
    type: ANNOUNCEMENT_SAVE
})

export const saveAnnouncement = ( onSuccess ) => {
    return (dispatch, getState) => {
        dispatch( load() )
        // console.log( getState().announcement )
        return axios.post( process.env.REACT_APP_API_URL + 'announcement/new', getState().announcement )
                    .then( savedAnnouncement => {
                        //console.log('savedAnnouncement', saveAnnouncement)
                        localStorage.setItem('newAnnouncement', savedAnnouncement.data.message)
                        dispatch( save() )
                        onSuccess.history.push({ pathname: '/', state: {message: savedAnnouncement.data.message}})
                    })
                    .catch( error => {
                        console.log('Announcement Error: ', error);
                    })
    }
}