import React from 'react'
import NavBar from '../../component/NavBar';
import Footer from '../../component/Footer';
import Announcement from '../../component/Announcement';
import ReactGA from 'react-ga';

class JobAnnouncement extends React.Component {

    constructor(){
        super();
        if( process.env.NODE_ENV === 'production' ){
            ReactGA.initialize('UA-35234591-4');
            ReactGA.pageview(window.location.pathname);
        }
    }

    render() {
        return( 
            <div id="job-announcement">
                <NavBar />
                
                <Announcement />

                <Footer />
            </div>
        )
    }

}

export default JobAnnouncement;