import React from 'react';
import NavBar from '../../component/NavBar';
import SlideShow from '../../component/SlideShow';
import OurService from '../../component/OurService';
import OurMember from '../../component/OurMember';
import WhyUs from '../../component/WhyUs';
import ContactUs from '../../component/contactUs';
import Footer from '../../component/Footer';
import ReactGA from 'react-ga';

import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
import AnnouncementList from '../../component/AnnouncementList';

class HomepageView extends React.Component {

    constructor(){
        super();
        if( process.env.NODE_ENV === 'production' ){
            ReactGA.initialize('UA-35234591-4');
            ReactGA.set({ title: 'Home' });
            ReactGA.pageview(window.location.pathname);
        }
    }

    componentWillMount(){
        configureAnchors( { offset: -75, scrollDuration: 600 } )
    }

    render() {
        return (
            <div className="homepage-view">
                <NavBar />
                <SlideShow />
                <AnnouncementList />
                <ScrollableAnchor id={ 'ourService' }>
                    <OurService />
                </ScrollableAnchor>
                <ScrollableAnchor id={ 'ourMember' }>
                    <OurMember />
                </ScrollableAnchor>
                <ScrollableAnchor id={ 'whyUs' }>
                    <WhyUs />
                </ScrollableAnchor>
                <ScrollableAnchor id={ 'contactUs' }>
                    <ContactUs />
                </ScrollableAnchor>
                <Footer />
            </div>
        )
    }
}

export default HomepageView;