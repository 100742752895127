import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Element } from 'react-scroll';

import './index.css';

import neangTitya from './images/neang_titya.jpg';
import michaelDevel from './images/michael_devel.jpg';
import lyhour from './images/lyhour.jpeg';
import klinton from './images/klinton.jpg';

export default class OurMember extends Component {
  render() {
    return (
      <div className="member-container">
        <div className="member-wrapper">
          <div className="title">
            <h1>Our Members</h1>
          </div>
          <div className="description">
            <p>
              We are experienced IT staffs, who have been working in IT industry
              for more than 5 years. We understand the expectation of the
              company toward the candidates. We can anticipate the potential of
              the candidate toward the comapny's job requrements which normal
              recruiter will have never been able to predict.
            </p>
          </div>
          <div className="box">
            <div className="image">
              <img
                src={neangTitya}
                width={140}
                height={140}
                alt={'neang titya'}
              />
            </div>
            <div className="info">
              <div className="sub--info">
                <span>Mr. NEANG Titya</span>
                <span>Co-founder of ITJob</span>
                <span>Bachelor of Computer Science, RUPP</span>
                <span>Experience:</span>
                <span style={{ textIndent: '30px' }}>
                  IT Manager at Mepla-Kh Fitness Club
                </span>
                <span>IT Supports at Smart Axiata Co., Ltd</span>
                <span>
                  IT Supports at Saint Blanquat HR & Management Consulting
                </span>
              </div>
            </div>
            <div className="social">
              <Link
                to={{
                  pathname: 'https://www.linkedin.com/in/titya-neang',
                }}
                target="_blank"
                className="sub--social"
              >
                <img
                  src={
                    'https://i0.wp.com/www.vectorico.com/wp-content/uploads/2018/02/LinkedIn-Logo-R.png?resize=300%2C265'
                  }
                  alt=""
                  className="logo"
                />
              </Link>
            </div>
          </div>
          <div className="box">
            <div className="image">
              <img
                src={michaelDevel}
                width={140}
                height={140}
                alt={'chea sowattana'}
              />
            </div>
            <div className="info">
              <div className="sub--info">
                <span>Mr. CHEA Sowattana</span>
                <span>Co-founder of ITJob</span>
                <span>Bachelor of Computer Science, RUPP</span>
                <span>Master of Computer Science, Mahidol University</span>
                <span>Experience:</span>
                <span>Backend Developer at Sabay Digital, Cambodia</span>
                <span>Backend Developer at Say Digital Co., Ltd, Thailand</span>
                <span>
                  Computer Technical Officer at Mahidol University, Thailand
                </span>
                <span>
                  iOS Developer ( Objective-C ) at iELove Company, Cambodia
                </span>
              </div>
            </div>
            <div className="social">
              <Link
                to={{
                  pathname: 'https://www.linkedin.com/in/chea-sowattana',
                }}
                target="_blank"
                className="sub--social"
              >
                <img
                  src={
                    'https://i0.wp.com/www.vectorico.com/wp-content/uploads/2018/02/LinkedIn-Logo-R.png?resize=300%2C265'
                  }
                  alt=""
                  className="logo"
                />
              </Link>
            </div>
          </div>
          <div className="box">
            <div className="image">
              <img src={lyhour} width={140} height={140} alt={'Lyhour Porn'} />
            </div>
            <div className="info">
              <div className="sub--info">
                <span>Mr. PORN Lyhour</span>
                <span>Co-founder and Recruitment Specialist at ITJob</span>
                <span>
                  Master's degree in Business, Management, Marketing, and
                  Related Support Services, NUM
                </span>
                <span>Experience:</span>
                <span style={{ textIndent: '30px' }}>
                  Senior Recruitment Specialist
                </span>
              </div>
            </div>
            <div className="social">
              <Link
                to={{
                  pathname:
                    'https://www.linkedin.com/in/lyhour-chris-379091239',
                }}
                target="_blank"
                className="sub--social"
              >
                <img
                  src={
                    'https://i0.wp.com/www.vectorico.com/wp-content/uploads/2018/02/LinkedIn-Logo-R.png?resize=300%2C265'
                  }
                  alt=""
                  className="logo"
                />
              </Link>
            </div>
          </div>
          <div className="box">
            <div className="image">
              <img src={klinton} width={140} height={140} alt={'APP'} />
            </div>
            <div className="info">
              <div className="sub--info">
                <span>Mr. CHHUN Klinton</span>
                <span>Intern Web Developer at ITJob</span>
                <span>
                  Bachelor of Information and Communication Technology, Mahidol
                  University
                </span>
                <span>Experience:</span>
                <span style={{ textIndent: '30px' }}>
                  Software Developer at ITJob Cambodia
                </span>
              </div>
            </div>
            <div className="social">
              <Link
                to={{
                  pathname: 'https://www.linkedin.com/in/klinton-c',
                }}
                target="_blank"
                className="sub--social"
              >
                <img
                  src={
                    'https://i0.wp.com/www.vectorico.com/wp-content/uploads/2018/02/LinkedIn-Logo-R.png?resize=300%2C265'
                  }
                  alt=""
                  className="logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
