import { lifecycle } from 'recompose';
// import { loadReCaptcha } from 'react-recaptcha-google'

export default () =>
  lifecycle({
    // componentWillMount() {
    //     const { loadJob } = this.props;
    //     loadJob();
    // },

    componentDidMount() {
      window.scrollTo(0, 0);
      const { loadJob } = this.props;
      loadJob();
      //loadReCaptcha();
      if (this.captchaDemo) {
        this.captchaDemo.reset();
      }
    },
  });
