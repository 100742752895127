import React from 'react'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import { Icon, Button, Toolbar } from './component'

import InsertImages from 'slate-drop-or-paste-images'

import Image from './Image'
import initialValue from './value.json'
import './index.css'

const DEFAULT_NODE = 'paragraph'

class Article extends React.Component {
    schema  = {
        blocks: {
            image: {
                isVoid: true
            }
        }
    };
    plugins = [
        InsertImages({
            extensions: [ 'jpg', 'jpeg', 'png' ],
            insertImage: ( change, file ) => {
                return change.insertBlock( {
                    type: 'image',
                    isVoid: true,
                    data: { file },
                } )
            },
        }),
    ]

    state = {
        user_id: localStorage.getItem('id'),
        title: '',
        isTitleValid: false,
        value: Value.fromJSON( initialValue )
    }

    onChange = ( { value } ) => {
        this.setState( { value } );
    }

    hasMark = type => {
        const { value } = this.state
        return value.activeMarks.some(mark => mark.type === type)
    }

    hasBlock = type => {
        const { value } = this.state
        return value.blocks.some(node => node.type === type)
    }

    //hasInsideBlock = type => {
        //const { value } = this.state;
        //value.blocks.some( node => {
        //    node.nodes.some( n => {
                //console.log( 'type:', node.type, n )
        //    });  
        //} )
    //}

    renderMarkButton = (type, icon) => {
        const isActive = this.hasMark(type)
    
        return (
          <Button
            active={isActive}
            onMouseDown={event => this.onClickMark(event, type)}
          >
            <Icon>{icon}</Icon>
          </Button>
        )
      }

    renderBlockButton = (type, icon) => {
        let isActive = this.hasBlock(type)
        //this.hasInsideBlock( type )
        if (['numbered-list', 'bulleted-list'].includes(type)) {
            const { value: { document, blocks } } = this.state

            if (blocks.size > 0) {
                const parent = document.getParent(blocks.first().key)
                isActive = this.hasBlock('list-item') && parent && parent.type === type
            }
        }

        return (
            <Button
                active={isActive}
                onMouseDown={event => this.onClickBlock(event, type)}
            >
                <Icon>{icon}</Icon>
            </Button>
        )
    }

    renderNode = (props, editor, next) => {
        const { attributes, children, node } = props

        console.log( this.state.value.toJSON() )
        //console.log( `align-${node.type}` )
        switch (node.type) {
            case 'align-left':
                return <div style={{ textAlign: "left" }} {...attributes}>{children}</div>
            case 'align-center':
                return <div style={{ textAlign: "center" }} {...attributes}>{children}</div>
            case 'align-right':
                return <div style={{ textAlign: "right" }} {...attributes}>{children}</div>
            case 'block-quote':
                return <blockquote {...attributes}>{children}</blockquote>
            case 'bulleted-list':
                return <ul {...attributes}>{children}</ul>
            case 'heading-one':
                return <h1 {...attributes}>{children}</h1>
            case 'heading-two':
                return <h2 {...attributes}>{children}</h2>
            case 'list-item':
                return <li {...attributes}>{children}</li>
            case 'numbered-list':
                return <ol {...attributes}>{children}</ol>
            case 'image':
                //console.log( 'attr_switch', attributes )
                return <Image {...props} />
            default:
                return next()
        }
    }

    renderMark = (props, editor, next) => {
        const { children, mark, attributes } = props
    
        switch (mark.type) {
            case 'bold':
                return <strong {...attributes}>{children}</strong>
            case 'code':
                return <code {...attributes}>{children}</code>
            case 'italic':
                return <em {...attributes}>{children}</em>
            case 'underlined':
                return <u {...attributes}>{children}</u>
            default:
                return next()
        }
    }

    onClickMark = (event, type) => {
        event.preventDefault()
        this.editor.toggleMark( type )
    }
    onClickBlock = (event, type) => {
        event.preventDefault()

        const { editor } = this
        const { value } = editor
        const { document } = value

        //console.log( 'editor', this )

        // Handle everything but list buttons.
        if (type !== 'bulleted-list' && type !== 'numbered-list') {
            const isActive = this.hasBlock(type)
            const isList = this.hasBlock('list-item')

            const isType = value.blocks.some( block => {
                //console.log( 'block', block )
                //console.log( 'parent', document.getClosest(block.key, parent => parent.type === type) )
                return !!document.getClosest(block.key, parent => parent.type === type)
            } );
            
            const isAlignedLeft = this.hasBlock( 'align-left' )
            const isAlignedCenter = this.hasBlock( 'align-center' )
            const isAlignedRight = this.hasBlock( 'align-right' )

            const isHeadingOne = this.hasBlock( 'heading-one' )
            const isHeadingTwo = this.hasBlock( 'heading-two' )

            if (isList) {
                editor
                    .setBlocks(isActive ? DEFAULT_NODE : type)
                    .unwrapBlock('bulleted-list')
                    .unwrapBlock('numbered-list')
            } else if( isType ) {
                editor.unwrapBlock( type )
            } else if( isAlignedLeft ){
                if( type !== 'heading-one' && type !== 'heading-two' )
                    editor.setBlocks( type )
                else
                    editor.setBlocks( 'align-left' )
                        .wrapBlock( type )
            } else if( isAlignedCenter ){
                if( type !== 'heading-one' && type !== 'heading-two' )
                    editor.setBlocks( type )
                else
                    editor.setBlocks( 'align-center' )
                        .wrapBlock( type )
            } else if( isAlignedRight ){
                if( type !== 'heading-one' && type !== 'heading-two' )
                    editor.setBlocks( type )
                else
                    editor.setBlocks( 'align-right' )
                        .wrapBlock( type )
            } else if( isHeadingOne ){
                //console.log( 'heading-one' )
                if( !isActive )
                    editor.setBlocks(type)
                        .wrapBlock( 'heading-one' )
                else
                    editor.setBlocks( DEFAULT_NODE )
                        .unwrapBlock( 'heading-one' )
            } else if( isHeadingTwo ) {
                if( !isActive )
                    editor.setBlocks(type)
                        .wrapBlock( 'heading-two' )
                else
                    editor.setBlocks( DEFAULT_NODE )
                        .unwrapBlock( 'heading-two' )
            } else {
                //console.log( 'here' )
                editor.setBlocks( isActive ? DEFAULT_NODE : type )
            }
        } else {
            // Handle the extra wrapping required for list buttons.
            const isList = this.hasBlock('list-item')
            const isType = value.blocks.some(block => {
                return !!document.getClosest(block.key, parent => parent.type === type)
            })

            if (isList && isType) {
                editor
                    .setBlocks(DEFAULT_NODE)
                    .unwrapBlock('bulleted-list')
                    .unwrapBlock('numbered-list')
            } else if (isList) {
                editor
                    .unwrapBlock(
                        type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
                    )
                    .wrapBlock(type)
            } else {
                editor.setBlocks('list-item').wrapBlock(type)
            }
        }
    }

    ref = editor => {
        this.editor = editor
    }

    fieldValidation = (e) => {
        const input = e.target.name;
        const value = e.target.value;
        //console.log( 'value', value )
        switch(input){
            case 'title': {
                let isTitleValid = value.length === 0 ? false : true;
                if(isTitleValid){
                    this.setState({title: value, isTitleValid});
                }
                break;
            }
            default: break;
        }
    }

    render() {
        return (
            <div className="article-container">
                { console.log(this.state) }
                <div className="label">
                    Title:
                </div>
                <div className="article_input">
                    <input name="title" onChange={this.fieldValidation} />
                </div>
                <div className="label">
                    Article
                </div>
                <div className="">
                    <Toolbar>
                        {this.renderMarkButton('bold', 'format_bold')}
                        {this.renderMarkButton('italic', 'format_italic')}
                        {this.renderMarkButton('underlined', 'format_underlined')}
                        {this.renderMarkButton('code', 'code')}
                        {this.renderBlockButton('heading-one', 'looks_one')}
                        {this.renderBlockButton('heading-two', 'looks_two')}
                        {this.renderBlockButton('block-quote', 'format_quote')}
                        {this.renderBlockButton('numbered-list', 'format_list_numbered')}
                        {this.renderBlockButton('bulleted-list', 'format_list_bulleted')}
                        {this.renderBlockButton('align-left', 'format_align_left')}
                        {this.renderBlockButton('align-center', 'format_align_center')}
                        {this.renderBlockButton('align-right', 'format_align_right')}
                    </Toolbar>
                    <Editor
                        schema = { this.schema }
                        plugins = { this.plugins }
                        ref = { this.ref }
                        value = { this.state.value }
                        onChange = { this.onChange }
                        renderNode = { this.renderNode }
                        renderMark = { this.renderMark }
                    />
                    <div>
                        <Button name="save" onClick={ () => this.props.saveHandler( this.state ) }>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default Article;