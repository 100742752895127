import React from 'react'
import './index.css';

export default class WhyUs extends React.Component {
    render(){
        return(
            <div className="why-us">
                <div className="title">
                    <h1>Why Us?</h1>
                </div>
                <div className="description">
                    <p>
                        We are here to provide your company with quality IT staffs. All the candidates are invited to go through tests
                        in technical skill and bahavioral.
                    </p>
                    <p>
                        We are IT-er, so we understand IT job requirements better than the other recruiters. This will reduce the
                        wasting time for your company to interview unqualified candidates, because the proposed candidates are considerably
                        qualified in their IT skills and working attitude.
                    </p>
                    <div className="mission-vision-holder">
                        <div className="our-vision">
                            <span className="our-vision-head">Our Vision</span>
                            <p>We are aiming to be the greatest IT recruiter in Cambodia. We are going to help ITer in Cambodia to get 
                                a suitable job in relevant to their qualifications. Simultaneously, we are assisting companies to pay smaller salary
                                with greater and potential IT staffs.
                            </p>
                        </div>
                        <div className="our-mission">
                            <span className="our-mission-head">Our Mission</span>
                            <p>
                                We are willing to propose quality candidates rather than the random ones. We qualify candidates through tests, both 
                                behavioural and technical. We are trying to classify the quality of candidates by job types, so every IT-er can get a job 
                                in according to their qualifications and experiences. ONLY IT-er UNDERSTAND IT-er. ;)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
//export default WhyUs;