import * as JobActions from '../actions/job'

const updateAllJobs = ( state, action ) => {
    return {
        ...state,
        jobs: action.data,
        isLoaded: true
    }
}

const initialState = {
    isLoaded: false
}

const allJobReducer = ( state = initialState, action ) => {
    switch( action.type ){
        case JobActions.JOBS_REQUEST_SUCCESS: {
            return updateAllJobs( state, action );
        }
        default: return state;
    }
}

export default allJobReducer;