import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import formateDate from '../../utils/FormateDate';

class BlogPost extends React.Component {
  listAllposts() {
    // console.log('all posts:',this.props.posts);
    this.props.posts.sort((a, b) => new Date(b.updated) - new Date(a.updated));
    return this.props.posts.map((post, index) => (
      <a
        key={index}
        href={`/post-content/${post.id}`}
        className="list-group-item list-group-item-action"
      >
        <div className="d-flex vw-100 justify-content-between">
          <h5 className="mb-1">{post.title}</h5>
        </div>
        <small>{post.author.displayName}, </small>
        <small>{formateDate(post.updated)}</small>
      </a>
    ));
  }

  render() {
    return (
      <div className="list-group">
        {this.props.posts ? (
          this.listAllposts()
        ) : (
          <h1
            style={{
              fontWeight: '600',
              color: 'rgba(192, 32, 45, 0.904)',
              textAlign: 'center',
              fontSize: '5em',
            }}
          >
            <FontAwesomeIcon icon={faSpinner} spin />
          </h1>
        )}
      </div>
    );
  }
}

export default BlogPost;
