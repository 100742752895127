import React from 'react'
import Helmet from 'react-helmet';
import NavBar from '../../component/NavBar';
import BlogPosts from'../../component/BlogPostList';
//import ABAlogo from'../../../public/assets/images/ztozetb7xca1pg2fzvyj.png';
import './index.css'

class BlogPostView extends React.Component {
    render(){
        return (
            <div className="blogPosts">
                <Helmet>
                    <title>ITJob | អត្ថបទបច្ចេកទេសកុំព្យូទ័រ</title>
                    <meta property="og:title" content='ITJob | អត្ថបទបច្ចេកទេសកុំព្យូទ័រ' />
                    <meta property="og:description" content='ITJob provides short, but comprehensive article for technical stuffs. We are primarily focusing on Javascript programming, but we still provide some contents on networking as well. Follow us on FB @itjobcambodia.' />
                </Helmet>
                <NavBar />
                <div className='blogpost-container'>
                    <div className="row">
                        <div className="col-sm-8 mb-3">
                            <h2 className='postTitle font-weight-bold mb-4'>អត្ថបទបច្ចេកទេសកុំព្យូទ័រ</h2>
                            <BlogPosts />
                        </div>
                        <div className="col-sm-4">
                            <img src='/assets/images/home_service.png' width='95%' alt='home-service' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default BlogPostView;