import React from 'react';
import { Helmet } from 'react-helmet';

import NavBar from '../../component/NavBar';
import Footer from '../../component/Footer';
import SignIn from '../../component/Login';

class Login extends React.Component {
  render() {
    return (
      <div className="login-view">
        <Helmet>
          <title>ITJob | Login</title>
          <meta property="og:title" content="ITJob | Login" />
        </Helmet>
        <NavBar />
        <SignIn />
        {/* <div style={{ minHeight: "70vh", padding:"15vh 10%" }}>
            <h1 style={{ color:"rgba(192, 32, 45, 0.904)" }}>This page is under contruction.</h1>
            <p>We are planning the Sign Up and Login process here. In the near future, we will be up and running. Stay Tune ;)</p>
            <p>
                For the job seeker, you will be able to register and leave your CV with us. We will review and match you to a right job.
                If you have an interesting resume, we will contact you for further interview before proposing you to the client.
            </p>
            <p>
                For the Company/Client, you will be able to register and post IT job positions. Furthermore, you can search around the website
                for the right candidate for yourself and contact them through us.
            </p>
            <p>
                We provide FREE service of registration and posting. It will always be free. ;)
            </p>
        </div> */}
        <Footer />
      </div>
    );
  }
}

export default Login;
