import React from 'react';
import { Link } from 'react-router-dom';
//import { Link } from 'react-scroll'
import './index.css';
import logo from './images/logo-itjob.png';
import add from './images/add.png';
import dropdown from './images/dropdown-arrow.png';
import user from './images/user.png';
import Fade from 'react-reveal/Fade';
import { instance as AuthInstance } from '../Auth/auth';

class NavigationBar extends React.Component {
  navRightLinks = () => {
    return (
      <div className="navbar-right">
        <div className="menu-bar">
          <ul>
            <li>
              <a href="/blog-posts">Blog Posts</a>
            </li>
            <li>
              <a
                href={
                  (this.props.location.pathname === '/'
                    ? ''
                    : process.env.REACT_APP_APP_URL) + '#ourService'
                }
              >
                Our Services
              </a>
            </li>
            <li>
              <a
                href={
                  (this.props.location.pathname === '/'
                    ? ''
                    : process.env.REACT_APP_APP_URL) + '#ourMember'
                }
              >
                Our Members
              </a>
            </li>
            <li>
              <a
                href={
                  (this.props.location.pathname === '/'
                    ? ''
                    : process.env.REACT_APP_APP_URL) + '#whyUs'
                }
              >
                Why Us?
              </a>
            </li>
            <li>
              <a
                href={
                  (this.props.location.pathname === '/'
                    ? ''
                    : process.env.REACT_APP_APP_URL) + '#contactUs'
                }
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  navRightLinksMedium = () => {
    return (
      <div className="menu-bar-md">
        <ul>
          <li>
            <a href="/blog-posts">Blog Posts</a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#ourService'
              }
            >
              Our Services
            </a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#ourMember'
              }
            >
              Our Members
            </a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#whyUs'
              }
            >
              Why Us?
            </a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#contactUs'
              }
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    );
  };

  navRightApply = () => {
    return (
      <div className="navbar-right-apply">
        <div className="apply-button">
          <a href="/apply">
            Apply Your CV
            <br /> for Free
          </a>
        </div>
      </div>
    );
  };

  navRightLinksSmall = () => {
    return (
      <div className="menu-bar-sm">
        <ul>
          <li>
            <a href="/blog-posts">Blog Posts</a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#ourService'
              }
            >
              Our Services
            </a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#ourMember'
              }
            >
              Our Members
            </a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#whyUs'
              }
            >
              Why Us?
            </a>
          </li>
          <li>
            <a
              href={
                (this.props.location.pathname === '/'
                  ? ''
                  : process.env.REACT_APP_APP_URL) + '#contactUs'
              }
            >
              Contact Us
            </a>
          </li>
          <li>
            <a href="/apply">Apply Your CV for Free</a>
          </li>
        </ul>
      </div>
    );
  };

  navHumMedium = () => {
    return (
      <div className="nav-hum-md">
        <div className="humburger" onClick={this.burgerToggle}>
          <div className="hum-line"></div>
          <div className="hum-line"></div>
          <div className="hum-line"></div>
        </div>
        <div className="navbar-right-md">{this.navRightLinksMedium()}</div>
      </div>
    );
  };

  navHumSmall = () => {
    return (
      <div className="nav-hum-sm">
        <div className="humburger-sm" onClick={this.burgerToggleSmall}>
          <div className="hum-line-sm"></div>
          <div className="hum-line-sm"></div>
          <div className="hum-line-sm"></div>
        </div>
        <div className="navbar-right-sm">{this.navRightLinksSmall()}</div>
      </div>
    );
  };

  afterLogin = () => {
    return (
      <div className="login-navbar-right">
        <Link to="/user-job-list" className="new-job">
          <span className="new-job-text">Posted Jobs</span>
        </Link>
        <Link to="/new/article" className="new-job">
          <img src={add} alt="" className="add-pic" />
          <span className="new-job-text">Add Article</span>
        </Link>
        <Link to="/posting" className="new-job">
          <img src={add} alt="" className="add-pic" />
          <span className="new-job-text">New Job</span>
        </Link>
        <div className="user-login" ref="userLogin">
          <div className="user" onClick={this.handleClick.bind(this)}>
            <img src={user} alt="" className="user-pic" />
            <span className="username-login">
              {localStorage.getItem('firstname') +
                ' ' +
                localStorage.getItem('lastname')}
            </span>
            {/* <span className="username-login">Chea</span> */}
            <img src={dropdown} alt="" className="dropdown" />
          </div>
          <Fade top when={this.state.menuShow} distance="20%" duration={500}>
            <div className="user-menu" style={{ width: this.state.menuWidth }}>
              <Link to="/profile" className="profile">
                <span>My Profile</span>
              </Link>
              <div
                className="line-under-profile"
                style={{ width: this.state.menuWidth - 30 }}
              ></div>
              <Link to="#" className="logout" onClick={this.props.handleLogout}>
                <span>Logout</span>
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="navbar">
        <div className="top-menu">
          <div className="navbar-left">
            <Link to="/" className="navbar_brand">
              <img src={logo} alt="" className="logo" />
              <span className="logo-text">
                {' '}
                Start your
                <br />
                career with us
              </span>
            </Link>
          </div>
          {AuthInstance.isAuthenticated()
            ? this.afterLogin()
            : this.navRightLinks()}
        </div>
        {AuthInstance.isAuthenticated() ? '' : this.navHumMedium()}
        {AuthInstance.isAuthenticated() ? '' : this.navRightApply()}
        {AuthInstance.isAuthenticated() ? '' : this.navHumSmall()}
      </div>
    );
  }

  burgerToggle = () => {
    let linksEl = document.querySelector('.navbar-right-md');
    if (linksEl.style.display === 'block') {
      linksEl.style.display = 'none';
    } else {
      linksEl.style.display = 'block';
    }
  };

  burgerToggleSmall = () => {
    let linksE2 = document.querySelector('.navbar-right-sm');
    if (linksE2.style.display === 'block') {
      linksE2.style.display = 'none';
    } else {
      linksE2.style.display = 'block';
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      menuWidth: 0,
      menuShow: false,
    };
  }

  handleClick() {
    this.setState({ menuShow: !this.state.menuShow });
    //let { clientHeight, clientWidth } = this.refs.userLogin;
    let { clientWidth } = this.refs.userLogin;
    this.setState({ menuWidth: clientWidth });
  }
}

export default NavigationBar;
