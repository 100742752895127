import { lifecycle } from 'recompose';

export default () => lifecycle({
    
    componentWillMount() {
        const { loadJob } = this.props;
        if( this.props.location.pathname !== '/posting') loadJob();
    },
    
    componentDidMount() {
        window.scrollTo(0,0)
    }

});