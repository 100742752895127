import { combineReducers } from "redux";
import authReducers from "./reducers/auth_reducers";
import profile from "./reducers/profile";
import announcement from "./reducers/announcement";
import job from "./reducers/job";
import allJobs from "./reducers/allJobs";
import jobUser from "./reducers/allJobForUser";
import uploadJob from "./reducers/applyJob";

const rootReducer = combineReducers({
  auth: authReducers,
  profile,
  announcement,
  job,
  allJobs,
  jobUser,
  uploadJob,
});

export default rootReducer;
