import React from 'react';
import './index.css';
import image from './images/recruitment-process.png';

class OurService extends React.Component {
    render() {
        return (
            <div className="our-service">
                <div className="title">
                    <h1>Our Services</h1>
                </div>
                <div className="description">
                    <p>
                        We are a recruitment agency, but we are totally a different one. Literally, we are focusing on
                        recruiting potential information technology (IT) staffs only; Not the other positions.
                        We are aiming to provide the service that will improve the chance for every company to meet
                        the right candidate(s) for the right IT job position.
                    </p>
                </div>
                <div>
                    <img src={ image } width="100%" alt={ "Our Recruitment Process" } />
                </div>
                <div className="our-process">
                    <div className="process-wrapper">
                        <div className="block">Analyse Client's Requirements</div>
                        <div className="block">Review Candidates Background</div>
                        <div className="block">Check out the References</div>
                        <div className="block">On-call Interview for Working Experiences</div>
                        <div className="block">Face-to-Face Interview for Behavioral and Ethical Tests</div>
                        <div className="block">Computer Technical Skill Tests</div>
                        <div className="block">Classify Candidate's Potential Skills by Test Results</div>
                        <div className="block">Propose the Potential Candidate to the Right Job</div>
                        <div className="block">Annouce the Employment Result</div>
                        <div className="block">Finalize the Employee Process</div>
                        <div style={{ clear: 'left' }}></div>
                    </div>
                </div>
                <div className="description">
                    <p>
                        We have divided our recruitment into three sections. First of all, we try to collect the bunch of IT Candidates
                        through their CV submission. Secondly, we try to communicate with the clients ( Companies ) for the
                        job requirements. The requirements should include the detail of job responsibilities and the range of 
                        employment salary. Lastly, we analyse, select the potential candidate, and propose the right one
                        to the client for the final consideration.
                    </p>
                </div>
            </div>
        )
    }
}

export default OurService;