import React from 'react';
import './index.css'

class Welcome extends React.Component{
    render(){
        return(
            <div className="container">
                <div>
                    <h1>Welcome to ITJob!</h1>
                    <p>Brighter Life Ahead</p>
                    { console.log('Welcome') }
                </div>
            </div>
        );
    }
}
export default Welcome;