import { compose } from 'recompose';
import JobList from './presenter';
import preload from './preload';
import { connect } from 'react-redux';
import * as JobActions from '../../actions/job';

const mapStateToProps = ( state ) => ({
    allJobs: state.allJobs
});
const mapDispatchToProps = ( dispatch, ownProps ) => ({
    loadAllJobs: () => dispatch( JobActions.loadAllJobs() ),
});

export default compose(
    connect( mapStateToProps, mapDispatchToProps ),
    preload()
)( JobList );