import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import LoginView from './presenter';
import { connect } from 'react-redux';

import * as ProfileActions from '../../actions/profile';
import * as LoginActions from '../../actions/auth';

const withSubmitHandler = withHandlers({
  handleSubmitSignup: (props) => () => {
    props.saveChanges(props.profile);
  },
  handleSubmitLogin: (props) => (emailLogin, passwordLogin) => {
    props.login(emailLogin, passwordLogin);
  },
});

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProfile: (profile) => {
    dispatch(ProfileActions.set(profile));
  },
  saveChanges: (profile) => {
    dispatch(ProfileActions.saveProfile(ownProps));
  },
  login: (emailLogin, passwordLogin) =>
    dispatch(LoginActions.login(emailLogin, passwordLogin, ownProps)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withSubmitHandler
)(LoginView);
