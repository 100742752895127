import React from 'react'
import NavBar from '../../component/NavBar';
import Posting from '../../component/Posting';
import Footer from '../../component/Footer';
import ReactGA from 'react-ga';

class PostingView extends React.Component{

    constructor(){
        super();

        if( process.env.NODE_ENV === 'production' ){
            ReactGA.initialize('UA-35234591-4');
            ReactGA.pageview(window.location.pathname);
        }
    }

    render(){
        return(
            <div className="posting-view">
                <NavBar />
                <Posting />
                <Footer />
            </div>
        )
    }
}

export default PostingView