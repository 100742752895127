import axios from 'axios';
import swal from 'sweetalert';

export const APPLY_SUCCESS = 'APPLY_SUCCESS';
export const APPLY_FAILURE = 'APPLY_FAILURE';
export const APPLY_LOADING = 'APPLY_LOADING';

export const applyLoading = () => ({
    type: APPLY_LOADING
});

export const applySuccess = () => ({
    type: APPLY_SUCCESS
});

export const applyFail = () => ({
    type: APPLY_FAILURE
});

export const applyForm = ( data ) => {
    return ( dispatch ) => {
        dispatch( applyLoading() )
        // console.log('applyForm', data)
        return axios.post( process.env.REACT_APP_API_URL + 'upload/apply-job', data)
                    .then(appliedJob => {
                        // console.log('apply-job:',appliedJob);
                        dispatch( applySuccess() )
                        swal({
                            title: "Your application has been applied!",
                            text: "Please wait the contact from us!",
                            icon: "success",
                            closeOnClickOutside: false,
                        });
                    })
                    .catch(error => {
                        dispatch( applyFail() )
                        swal({
                            title: "Sorry!",
                            text: "There is some mistakes occur!",
                            icon: "error",
                            closeOnClickOutside: false,
                        });
                        // console.log('Cannot apply this job',error.response.data)
                    })
    }
}