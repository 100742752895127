import React from 'react';
import Recaptcha from 'react-recaptcha';
import LoadVerify from '../../utils/LoadVerify';
import RequirementEdit from './requirement';
import DescriptionEdit from './description';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Posting extends React.Component {
  componentDidMount() {
    LoadVerify();
  }
  constructor(props) {
    super(props);
    if (this.props.location.pathname === '/posting') {
      this.state = {
        isVerified: false,

        jobTitleIsvalid: false,
        salaryFromIsvalid: false,
        salaryToIsvalid: false,
        formValidation: false,
      };
    } else {
      this.state = {
        isVerified: false,

        allFieldChanged: false,
        jobTitleIsvalid: true,
        salaryFromIsvalid: true,
        salaryToIsvalid: true,
        formValidation: false,
      };
    }
  }
  fieldValidation = (event) => {
    if (this.props.location.pathname === '/job-edit/' + this.props.job._id)
      this.setState({ allFieldChanged: true });
    let input = event.target.name;
    let value = event.target.value;
    switch (input) {
      case 'job-title': {
        let IsJobTitleValid = value.length === 0 ? false : true;
        this.props.updateAnnouncement({
          ...this.props.announcement,
          jobTitle: value,
        });
        this.setState({ jobTitleIsvalid: IsJobTitleValid });
        break;
      }
      case 'salary-from': {
        let isSalaryFromValid =
          (value.length !== 0 && !value.match(/^[0-9]+$/)) || value.length === 0
            ? false
            : true;
        this.props.updateAnnouncement({
          ...this.props.announcement,
          salaryFrom: value,
        });
        this.setState({ salaryFromIsvalid: isSalaryFromValid });
        break;
      }
      case 'salary-to': {
        let isSalaryToValid =
          (value.length !== 0 && !value.match(/^[0-9]+$/)) || value.length === 0
            ? false
            : true;
        this.props.updateAnnouncement({
          ...this.props.announcement,
          salaryTo: value,
        });
        this.setState({ salaryToIsvalid: isSalaryToValid });
        break;
      }
      case 'job-industry': {
        this.props.updateAnnouncement({
          ...this.props.announcement,
          industry: value,
        });
        break;
      }
      case 'job-location': {
        this.props.updateAnnouncement({
          ...this.props.announcement,
          location: value,
        });
        break;
      }
      default:
        break;
    }
  };
  formValidation = (event) => {
    event.preventDefault();
    if (this.props.location.pathname === '/job-edit/' + this.props.job._id) {
      if (!this.state.allFieldChanged && this.state.isVerified)
        this.props.updateAfterEditJob(this.props.job._id);
      else {
        if (
          this.state.jobTitleIsvalid &&
          this.state.salaryFromIsvalid &&
          this.state.salaryToIsvalid &&
          this.state.isVerified
        ) {
          this.props.updateAfterEditJob(this.props.job._id);
        }
      }
    } else {
      if (
        this.state.jobTitleIsvalid &&
        this.state.salaryFromIsvalid &&
        this.state.salaryToIsvalid &&
        this.state.isVerified
      ) {
        this.props.updateAnnouncement({
          ...this.props.announcement,
          userid: localStorage.getItem('id'),
        });
        this.props.handleSubmit();
      }
    }
    this.setState({ formValidation: true });
  };
  handleVerify() {
    if (this.state.isVerified) console.log('Verified');
    else console.log('Not verify');
  }
  LoadVerify() {
    console.log('Load verify success');
  }
  verifyCallback(response) {
    // console.log('response:',response);
    this.props.updateAnnouncement({
      ...this.props.announcement,
      captchaToken: response,
    });
    if (response) this.setState({ isVerified: true });
  }

  // componentDidUpdate(){
  //     console.log('state:',this.state);
  // }
  render() {
    return (
      <div className="posting_container">
        {}
        <div className="job">
          <div className="job-announce">
            <h1 style={{ fontWeight: '600' }}>Job Announcement</h1>
          </div>
          <form className="form-announce" onSubmit={this.formValidation}>
            <div className="job-title">
              <span className="job-title-text">Job Title: </span>
              <input
                type="text"
                className="job-title-input"
                name="job-title"
                style={
                  !this.state.formValidation
                    ? { border: '1px solid #ccc' }
                    : this.state.jobTitleIsvalid
                    ? { border: '1px solid #ccc' }
                    : { border: '1px solid red' }
                }
                onChange={this.fieldValidation}
                defaultValue={
                  this.props.location.pathname ===
                  '/job-edit/' + this.props.job._id
                    ? this.props.job.jobTitle
                    : ''
                }
              />
            </div>
            <p style={style}>
              {!this.state.formValidation
                ? ''
                : this.state.jobTitleIsvalid
                ? ''
                : 'Please fill out this field*'}
            </p>
            <div className="salary">
              <span className="salary-text">Salary Range: </span>
              <input
                type="text"
                className="salary-input"
                name="salary-from"
                style={
                  !this.state.formValidation
                    ? { border: '1px solid #ccc' }
                    : this.state.salaryFromIsvalid
                    ? { border: '1px solid #ccc' }
                    : { border: '1px solid red' }
                }
                onChange={this.fieldValidation}
                defaultValue={
                  this.props.location.pathname ===
                  '/job-edit/' + this.props.job._id
                    ? this.props.job.salaryFrom
                    : ''
                }
              />
              <span className="salary-to">To</span>
              <input
                type="text"
                className="salary-input"
                name="salary-to"
                style={
                  !this.state.formValidation
                    ? { border: '1px solid #ccc' }
                    : this.state.salaryToIsvalid
                    ? { border: '1px solid #ccc' }
                    : { border: '1px solid red' }
                }
                onChange={this.fieldValidation}
                defaultValue={
                  this.props.location.pathname ===
                  '/job-edit/' + this.props.job._id
                    ? this.props.job.salaryTo
                    : ''
                }
              />
              <span className="salary-current">( USD )</span>
            </div>
            <p style={style}>
              {!this.state.formValidation
                ? ''
                : this.state.salaryFromIsvalid && this.state.salaryToIsvalid
                ? ''
                : 'Please fill with number formart only*'}
            </p>
            <div className="job-industry">
              <span className="job-industry-text">Job Industry: </span>
              <input
                type="text"
                className="job-industry-input"
                name="job-industry"
                onChange={this.fieldValidation}
                defaultValue={
                  this.props.location.pathname ===
                  '/job-edit/' + this.props.job._id
                    ? this.props.job.industry
                    : ''
                }
              />
            </div>
            <div className="job-location">
              <span className="job-location-text">Job Location: </span>
              <input
                type="text"
                className="job-location-input"
                name="job-location"
                onChange={this.fieldValidation}
                defaultValue={
                  this.props.location.pathname ===
                  '/job-edit/' + this.props.job._id
                    ? this.props.job.location
                    : ''
                }
              />
            </div>
            <div className="job-des">
              <span className="des-text">Job Description: </span>
              <div className="des-edit" id="descriptionEdit">
                {this.props.location.pathname ===
                  '/job-edit/' + this.props.job._id &&
                this.props.job.isLoaded ? (
                  <DescriptionEdit
                    job={this.props.job}
                    updateAnnouncement={this.props.updateAnnouncement}
                    location={this.props.location}
                  />
                ) : (
                  ''
                )}
                {this.props.location.pathname === '/posting' ? (
                  <DescriptionEdit
                    updateAnnouncement={this.props.updateAnnouncement}
                    location={this.props.location}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="job-require">
              <span className="require-text">Job Requirements: </span>
              <div className="require-edit" id="requirementEdit">
                {this.props.location.pathname ===
                  '/job-edit/' + this.props.job._id &&
                this.props.job.isLoaded ? (
                  <RequirementEdit
                    job={this.props.job}
                    updateAnnouncement={this.props.updateAnnouncement}
                    location={this.props.location}
                  />
                ) : (
                  ''
                )}
                {this.props.location.pathname === '/posting' ? (
                  <RequirementEdit
                    updateAnnouncement={this.props.updateAnnouncement}
                    location={this.props.location}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="recaptcha" id="recaptcha">
              <Recaptcha
                sitekey={process.env.REACT_APP_SITE_KEY}
                render="explicit"
                verifyCallback={this.verifyCallback.bind(this)}
                onloadCallback={this.LoadVerify.bind(this)}
              />
              <p style={{ color: 'red', fontStyle: 'italic' }}>
                {!this.state.formValidation
                  ? ''
                  : this.state.isVerified
                  ? ''
                  : 'Please verify this*'}
              </p>
            </div>
            <div className="button">
              {this.props.announcement.isAnnouncementLoad ? (
                ''
              ) : (
                <input type="reset" value="Cancel" className="cancel" />
              )}
              {this.props.announcement.isAnnouncementLoad ? (
                <span
                  className="create"
                  style={{
                    overflow: 'visible',
                    width: '10%',
                    padding: '3px 10px',
                  }}
                >
                  {<FontAwesomeIcon icon={faSpinner} spin />}
                </span>
              ) : (
                <input
                  type="submit"
                  value={
                    this.props.location.pathname ===
                    '/job-edit/' + this.props.job._id
                      ? 'Save'
                      : 'Create '
                  }
                  className="create"
                />
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const style = {
  position: 'absolute',
  marginLeft: '16%',
  fontStyle: 'italic',
  color: 'red',
  fontSize: '13px',
  marginTop: '4px',
};
export default Posting;
