import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class JobList extends React.Component {
    listAlljobs(){
        const { jobs } = this.props.allJobs;
        return jobs.slice(jobs.length-3, jobs.length).map((job,index)=>
            <a key={index} href={`/announcement/${job._id}`} className="list-group-item list-group-item-action">
                <div className="d-flex vw-100 justify-content-between">
                    <h5 className="mb-1">{job.jobTitle}</h5>
                </div>
                <small className="float-left">Salary Range: {job.salaryFrom}-{job.salaryTo}</small>
                <small className="float-right">{job.location}</small>
            </a>
        );
    }

    render(){
        return (
            <div className="list-group">
                {
                    this.props.allJobs.isLoaded ? this.listAlljobs() :
                    <h1 style={{ fontWeight: "600", color:"rgba(192, 32, 45, 0.904)", textAlign:"center", fontSize: "5em"}}>
                        <FontAwesomeIcon icon={ faSpinner } spin />
                    </h1>
                }
            </div>
        )
    }

}

export default JobList;