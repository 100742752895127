import axios from 'axios';
import jwt from 'jsonwebtoken';
import CryptoJS from 'crypto-js';
import swal from 'sweetalert';

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_LOADFAIL = 'LOGIN_LOADFAIL'

export const loginLoading = () => ({
    type: LOGIN_LOADING
})
export const loginLoadFail = () => ({
    type: LOGIN_LOADFAIL
})
export const loginRequest = ( isAuthenticated ) => ({
    type: LOGIN_REQUEST,
    isAuthenticated
});

export const login = (email, password, ownProps) => {
    return ( dispatch ) => {
        dispatch( loginLoading() );
        const loginData = ( { email: email, password: password } );
        //console.log('loginData', loginData)
        return axios.post( process.env.REACT_APP_API_URL + 'login', loginData)
                    .then( loginInfo => {
                        console.log( 'loginInfo', loginInfo );
                        if( loginInfo.data.login ) {
                            const decryptedToken = CryptoJS.AES.decrypt( loginInfo.headers['x-auth-token'], "ewfWE@#%$!$)%^&*(lksdi" ).toString( CryptoJS.enc.Utf8 );
                            const decodedToken = jwt.decode( decryptedToken );
                            //console.log( decodedToken );
                            localStorage.setItem( 'id', decodedToken.id )
                            localStorage.setItem( 'firstname', decodedToken.firstname )
                            localStorage.setItem( 'lastname', decodedToken.lastname )
                            localStorage.setItem( 'token', decryptedToken )
                            localStorage.setItem( 'expire_at', decodedToken.exp )

                            dispatch( loginRequest( { isAuthenticated: true } ) )
                            ownProps.history.push( '/welcome' )
                        } else {
                            //console.log( 'fail' )
                            dispatch ( loginLoadFail() )
                            swal({
                                title: "The email or password is incorrect",
                                text: "Check your email and password, then type it again.",
                                icon: "error",
                                closeOnClickOutside: false,
                            });
                            dispatch( loginRequest( { isAuthenticated: false } ) )
                            ownProps.history.push('/login')
                        }
                    } ).catch( error => {
                        console.log(error);
                    } );
    }
}
