import * as AnnouncementActions from '../actions/announcement'

const updateAnnouncementDetail = ( state, action ) => {
    return {
        ...state,
        ...action.announcement,
        isAnnouncementLoad: false
    }
}

const initialState = { isAnnouncementLoad: false }

const announcementReducer = ( state = initialState, action ) => {
    switch( action.type ){
        case AnnouncementActions.ANNOUNCEMENT_LOAD: {
            return {
                ...state,
                isAnnouncementLoad: true
            }
        }
        case AnnouncementActions.ANNOUNCEMENT_SET: {
            return updateAnnouncementDetail(state, action);
        }
        case AnnouncementActions.ANNOUNCEMENT_SAVE: {
            return {
                ...state,
                isAnnouncementLoad: false
            }
        }
        default: return state;
    }
}

export default announcementReducer