import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Posting from './presenter';
import preload from './preload';

import * as JobActions from '../../actions/job';
import * as ApplyJobAction from '../../actions/applyJob';

const withSubmitHandler = withHandlers({
  handleApply: (props) => (candidateForm) => {
    props.applyForm(candidateForm);
  },
});

const mapStateToProps = (state) => ({
  job: state.job,
  upload: state.uploadJob,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadJob: () => dispatch(JobActions.load(ownProps.match.params.id)),
  applyForm: (candidateForm) => {
    dispatch(ApplyJobAction.applyForm(candidateForm));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  preload(),
  withSubmitHandler
)(Posting);
