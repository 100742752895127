import * as ProfileActions from '../actions/profile'

const updateProfileDetail = ( state, action ) => {
    return{
        ...state,
        ...action.profile,
        isProfileLoading: false
    }
}

const initialState = {
    isProfileLoading: false,
    isLoaded: false
}

const profileReducer = (state = initialState, action) => {
    switch( action.type ){
        // eslint-disable-next-line
        case ProfileActions.PROFILE_LOADING: {
            return {
                ...state,
                isProfileLoading: true
            };
            // eslint-disable-next-line
        };
        // eslint-disable-next-line
        case ProfileActions.PROFILE_SAVE: {
            return {
                ...state,
                isProfileLoading: false
            };
            // eslint-disable-next-line
        };
        // eslint-disable-next-line
        case ProfileActions.PROFILE_SET: {
            return updateProfileDetail(state, action)
            // eslint-disable-next-line
        };
        // eslint-disable-next-line
        case ProfileActions.PROFILE_LOAD_FAIL : {
            return {
                ...state,
                isProfileLoading: false
            };
            // eslint-disable-next-line
        };
        default: return state;
    }
}

export default profileReducer