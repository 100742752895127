import React from 'react';

class GoogleAdsense extends React.PureComponent {

    componentDidMount(){
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <div className='adsense'>
                {/* under-the-article */}
                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-5982585055902377"
                    data-ad-slot="2527179853"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
            </div>
        );
    }

}

export default GoogleAdsense;