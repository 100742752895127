import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import NavBar from './presenter';
import { connect } from 'react-redux';
import { loginRequest } from '../../actions/auth';

const withSubmitHandler = withHandlers({
  handleLogout: (props) => () => {
    //console.log( 'clear localstorage' );
    localStorage.clear();
    props.logout();
  },
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(loginRequest({ isAuthenticated: false }));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withSubmitHandler
)(NavBar);
