import * as JobActions from '../actions/job'

const updateJobDetail = ( state, action ) => {
    return {
        ...state,
        ...action.data,
        isLoaded: true
    }
}

const initialState = {
    isLoaded: false
}

const jobReducer = ( state = initialState, action ) => {
    switch( action.type ){
        case JobActions.JOB_REQUEST_SUCCESS: {
            return updateJobDetail(state, action);
        }
        case JobActions.JOB_REQUEST_LOAD: {
            return {
                ...state,
                isLoaded: false
            }
        }
        default: return state;
    }
}

export default jobReducer