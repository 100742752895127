import * as Auth from '../actions/auth'

const initialState = {
    isAuthenticated: false,
    loginLoading: false
};

export default function( state = initialState, action ){
    //console.log( action.type )
    switch( action.type ){
        case Auth.LOGIN_LOADING: return { ...state, loginLoading: true }
        case Auth.LOGIN_REQUEST: return { ...state, ...action.isAuthenticated }
        case Auth.LOGIN_LOADFAIL: return { ...state, loginLoading: false }
        default: return state;
    }
};