import React from 'react';
// import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './index.css';

class ContactForm extends React.Component {
  state = {
    email: '',
    firstname: '',
    lastname: '',
    message: '',
    captchaToken: '',
    sendEmail: '',
    msg: '',
  };
  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }
  verifyCallback(recaptchaToken) {
    this.setState({ captchaToken: recaptchaToken });
  }

  handlefield = (event) => {
    let input = event.target.name;
    let value = event.target.value;
    switch (input) {
      case 'email': {
        if (
          value.match(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
          )
        ) {
          event.target.className = 'form-control textbox-pill is-valid';
        } else event.target.className += ' is-invalid';
        this.setState({ email: value });
        break;
      }
      case 'firstname': {
        if (value !== '') {
          event.target.className = 'form-control textbox-left is-valid';
        } else event.target.className += ' is-invalid';
        this.setState({ firstname: value });
        break;
      }
      case 'lastname': {
        if (value !== '') {
          event.target.className = 'form-control textbox-right is-valid';
        } else event.target.className += ' is-invalid';
        this.setState({ lastname: value });
        break;
      }
      case 'message': {
        if (value !== '') {
          event.target.className = 'form-control is-valid';
        } else event.target.className += ' is-invalid';
        this.setState({ message: value });
        break;
      }
      default:
        break;
    }
  };

  onsubmit = (e) => {
    e.preventDefault();
    this.setState({ sendEmail: 'loading' });
    const contactInfo = {
      email: this.state.email,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      message: this.state.message,
      captchaToken: this.state.captchaToken,
    };
    axios
      .post(process.env.REACT_APP_API_URL + 'sendemail', contactInfo)
      .then((res) =>
        this.setState({ sendEmail: res.data.sendEmail, msg: res.data.msg })
      )
      .catch((error) => {
        this.captchaDemo.reset();
        this.setState({
          sendEmail: error.response.data.sendEmail,
          msg: error.response.data.msg,
        });
      });
  };

  componentDidMount() {
    //loadReCaptcha();
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  // componentDidUpdate(){
  //     console.log('state:',this.state);
  // }

  render() {
    return (
      <form
        className="pb-5"
        onSubmit={this.onsubmit}
        action={`${process.env.REACT_APP_API_URL}sendemail`}
        method="post"
      >
        <div className="form-group">
          <input
            type="email"
            className="form-control textbox-pill"
            id="InputEmail1"
            name="email"
            onChange={this.handlefield}
            placeholder="Email address"
          />
          <div className="invalid-feedback pl-2">
            Please provide a valid email.
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                type="text"
                className="form-control textbox-left"
                name="firstname"
                onChange={this.handlefield}
                placeholder="First name"
              />
              <div className="invalid-feedback pl-2">
                Please enter your firstname.
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <input
                type="text"
                className="form-control textbox-right"
                name="lastname"
                onChange={this.handlefield}
                placeholder="Last name"
              />
              <div className="invalid-feedback pl-2">
                Please enter your lastname.
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mb-5">
          <textarea
            className="form-control"
            id="InputTextarea"
            name="message"
            rows="3"
            onChange={this.handlefield}
            placeholder="Message"
          ></textarea>
          <div className="invalid-feedback pl-2">
            Please enter a message in the textarea.
          </div>
        </div>

        {/* {window.grecaptcha && window.grecaptcha.render ? (
          <ReCaptcha
            ref={(el) => {
              this.captchaDemo = el;
            }}
            size="normal"
            data-theme="dark"
            render="explicit"
            sitekey={process.env.REACT_APP_SITE_KEY}
            onloadCallback={this.onLoadRecaptcha}
            verifyCallback={this.verifyCallback}
            grecaptcha={window.grecaptcha}
          />
        ) : (
          ''
        )} */}
        <div>
          {this.state.sendEmail === 'loading' ? (
            <h3
              style={{
                fontWeight: '600',
                color: 'rgba(192, 32, 45, 0.904)',
                textAlign: 'center',
                fontSize: '2em',
              }}
            >
              <FontAwesomeIcon icon={faSpinner} spin />
            </h3>
          ) : this.state.sendEmail ? (
            <h3 className="text-center text-success">{this.state.msg}</h3>
          ) : (
            <div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btnpill btn-light px-4">
                  Submit
                </button>
              </div>
              <p className="text-center text-danger">{this.state.msg}</p>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default ContactForm;
