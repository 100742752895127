import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { instance as AuthInstance } from '../component/Auth/auth';

export default ({ protectedComponent: ProtectedComponent, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      AuthInstance.isAuthenticated() ? (
        <ProtectedComponent {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
