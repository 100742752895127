const LoadVerify = () => {
    
    const script = document.createElement('script')
    script.type = "text/javascript"
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
    script.async = true
    script.defer = true

    document.getElementById('recaptcha').appendChild( script )
}
export default LoadVerify