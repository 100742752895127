import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import UserJobList from './presenter';
import { connect } from 'react-redux';
import preload from './preload';
import * as JobActions from '../../actions/job';
import * as DeleteJobActions from '../../actions/deleteJob';
import * as UnpublishJobActions from '../../actions/unpublishJob';

const mapStateToProps = (state) => ({
  jobUser: state.jobUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadAllJobsForUser: () => dispatch(JobActions.loadAllJobsForUser()),
  deleteJob: (deleteList) =>
    dispatch(DeleteJobActions.deleteJob(deleteList, ownProps)),
  unpublishJob: (unpublishList) =>
    dispatch(UnpublishJobActions.unpublishJob(unpublishList, ownProps)),
  goToEditJob: (id) => {
    ownProps.history.push('/job-edit/' + id);
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  preload()
)(UserJobList);
