import * as JobActions from '../actions/job'

const updateAllJobsForUser = ( state, action ) => {
    return {
        ...state,
        allJobUser: action.data,
        isLoaded: true
    }
} 

const initialState = {
    isLoaded: false
}

const allJobUserReducer = ( state = initialState, action ) => {
    switch( action.type ){
        case JobActions.ALLJOBS_REQUEST_SUCCESS: {
            return updateAllJobsForUser( state, action );
        }
        default: return state;
    }
}

export default allJobUserReducer;