import React from 'react'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class AnnouncementList extends React.Component {

    listOfJobs = () => {
        const { jobs } = this.props.allJobs;
        return jobs.map( job => 
            <tr key={ job._id } onClick= { () => this.props.goToDetail( job._id ) }>
                <td>{ job.jobTitle }</td>
                <td className="job-industry" style={{ textAlign: "center" }}>{ job.industry ? job.industry : '-' }</td>
                <td style={{ textAlign: "center" }}>{ job.salaryFrom } - { job.salaryTo }</td>
                <td className="job-location" style={{ textAlign: job.location ? '' : 'center' }}>{ job.location ? job.location : '-' }</td>
            </tr>
        );
        
    }

    render() {
        return (
                <div className="announcement-list">
                    <table>
                        <thead>
                            <tr>
                                <th width="40%">Job Title</th>
                                <th className="job-industry" width="10%">Job Industry</th>
                                <th>Salary <span className="job-industry">Range </span>(USD)</th>
                                <th width="10%">Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.props.allJobs.isLoaded ? this.listOfJobs():
                                <tr><td colSpan="4"><h1 style={{ fontWeight: "600", color:"rgba(192, 32, 45, 0.904)", textAlign:"center", fontSize: "5em"}}><FontAwesomeIcon icon={ faSpinner } spin /></h1></td></tr> }
                        </tbody>
                    </table>
                </div>
        );
    }

}

export default AnnouncementList;