import React from 'react';
import './draggableUpload.css';
import {AnchorButton, Intent} from "@blueprintjs/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFilePdf, faFileWord, faFile, } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Icon } from 'react-icons-kit';
import { remove } from 'react-icons-kit/fa/remove'

class DraggableUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedFile: [],
            isFileValid:true,
            fileUploadStatus: false
        }
    }

    onFileLoad = (e) =>{
        const file = e.currentTarget.files[0]; 
        console.log('file:',file);
        if(file.type==='application/pdf'||file.type==='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||file.type==='application/msword'){
            let fileReader = new FileReader();
            // console.log('file:',fileReader.result);
            fileReader.onload = () => {
                // console.log("File LOADED: ", fileReader.result);
                const loadfile = {
                    name: file.name,
                    size: file.size,
                    type: file.type, 
                    data: fileReader.result,
                }
                this.addLoadFile(loadfile);
            }
            if(file){
                fileReader.readAsDataURL(file);
            }
        }else this.setState({isFileValid:false});
    }

    addLoadFile = (file) => {
        this.setState({ loadedFile:[ file ], fileUploadStatus: true ,isFileValid:true});
        this.props.callUploadBack( file, true );
    }

    removeLoadedFile = (file) => {
        this.setState((prevState => {
            let loadedFile = prevState.loadedFile;
            let newLoadedFile = _.filter(loadedFile, (ldFile) => {
                return ldFile !== file;
            });
            this.props.callUploadBack( newLoadedFile, false );
            return { loadedFile: newLoadedFile, fileUploadStatus: false }
        }))
    }

    render() {
        const { loadedFile, fileUploadStatus } = this.state;
        return (
            <div className="upload-inner" style={{display: "flex", flexDirection: "column" }}>
                {
                    !fileUploadStatus ? 
                    <div className="draggable">
                        <input type="file" id="file-browser-input" 
                            name="avatar"
                            ref={input => this.fileInput = input} 
                            onDragOver={(e) => {e.preventDefault(); e.stopPropagation();}}
                            onDrop={this.onFileLoad.bind(this)}
                            onChange={this.onFileLoad.bind(this)}
                        />
                        <FontAwesomeIcon icon={faCloudUploadAlt} className="cloud-icon"/> 
                        <div className="file-browser">
                            <AnchorButton text="Select your CV file" id="anchor"
                                intent={Intent.PRIMARY}
                                minimal={true}
                                onClick={() => this.fileInput.click()}
                            />
                        </div>
                       
                    </div> : 
                    <div className="files-preview">
                        {loadedFile.map((file, idx) => {
                            return <div className="file" key={idx}>
                                {
                                    file.type === 'application/pdf' ?
                                    <FontAwesomeIcon icon={faFilePdf} className="file-type"/> 
                                    : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 
                                    <FontAwesomeIcon icon={faFileWord} style={{color: '24a0ed'}} className="file-type"/> 
                                    // : (file.type === 'image/jpeg' || file.type === 'image/png') ?
                                    // <img src={file.data} alt=""/> 
                                    : <FontAwesomeIcon icon={faFile} className="file-type"/> 
                                }
                                <div className="remove-button">
                                    <span className="remove-btn" onClick={() => this.removeLoadedFile(file)}>
                                        <Icon icon={remove} size={19} />
                                    </span>
                                </div>
                            </div>
                        })}
                        {loadedFile.map((file, idx) => {
                            return <div className="file-detail" key={idx}>
                                <span>{file.name}</span>
                                <span>{file.size >= 1024 ? (file.size / 1024).toFixed(2)+" KB": file.size >= (1024 * 1024)? file.size+" MB": file.size+" B"}</span>
                            </div>
                        })}
                    </div>
                }
                 <span style = {message} className="upload-cv-file">
                    { !this.state.isFileValid ? '*File type must be PDF, Docx, or Doc' : ''}
                </span>
            </div>
        );
    }
};

const message = {
    color: 'red',
    fontSize: '13px'
}

export default DraggableUpload;