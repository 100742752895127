import React from 'react'
import NavBar from '../../component/NavBar';
import UserJobList from '../../component/UserJobList';
import Footer from '../../component/Footer/presenter';

class UserJobListView extends React.Component {
    render(){
        return(
            <div>
                <NavBar />
                <UserJobList />
                <Footer />
            </div>
        );
    }
}

export default UserJobListView;