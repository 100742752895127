import React from 'react'
// import { Link } from 'react-scroll';

import './index.css'
import fbLogo from './images/fb-logo.png'
import linkedInLogo from './images/linkedin-logo.png'
import gPlusLogo from './images/g-plus-logo.png'
import { instance as AuthInstance } from '../Auth/auth'

class Footer extends React.Component {
    footerMenu = () =>{
        return <div className="footer-menu">
            <ul>
                <div className="list-left">
                    <li><a href={ ( this.props.location.pathname === "/" ? '' : process.env.REACT_APP_APP_URL ) + "#ourService" }>Our Services</a></li>
                    <li><a href={ ( this.props.location.pathname === "/" ? '' : process.env.REACT_APP_APP_URL ) + "#ourMember" }>Our Members</a></li>
                </div>
                <div className="list-right">
                    <li><a href={ ( this.props.location.pathname === "/" ? '' : process.env.REACT_APP_APP_URL ) + "#whyUs" }>Why Us?</a></li>
                    <li><a href={ ( this.props.location.pathname === "/" ? '' : process.env.REACT_APP_APP_URL ) + "#contactUs" }>Contact Us</a></li>
                </div>
            </ul>
        </div>
    }
    render(){
        return (
            <div className="footer">
                { AuthInstance.isAuthenticated() ? <p style={{fontWeight: "600", textAlign: "center"}}>Follow us</p> : this.footerMenu()}
                <div className="footer-social-logo">
                    <ul>
                        <li><a href="https://www.facebook.com/ITJobCambodia/" target="_blank" rel="noopener noreferrer"><img src={ fbLogo } alt="fb" width="48" /></a></li>
                        <li><a href="https://www.linkedin.com/company/itjob-cambodia " target="_blank" rel="noopener noreferrer"><img src={ linkedInLogo } alt="linkedin" width="48" /></a></li>
                        <li><a href="https://plus.google.com/109082139700131194447" target="_blank" rel="noopener noreferrer"><img src={ gPlusLogo } alt="google-plus" width="48" /></a></li>
                    </ul>
                </div>
                <div className="footer-address">
                    #47, street R10, Sangkat Chba Ompov, Khan Chba Ompov,<br />Phnom Penh.
                </div>
                <div className="footer-copyright">
                    <div className="footer-copyright-inner">
                        Copyright &copy; 2020 ITJob Cambodia. All Right Reserved.
                    </div>
                </div>
            </div>
        )
    }

}

export default Footer;