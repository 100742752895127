import axios from 'axios';

export const JOBEDIT_UPDATE_SUCCESS = 'JOBEDIT_UPDATE_SUCCESS';
export const JOBEDIT_UPDATE_FAILED = 'JOBEDIT_UPDATE_FAILED';

export const jobEditUpdateSuccess = () => ({
  type: JOBEDIT_UPDATE_SUCCESS,
});
export const jobEditUpdateFailed = () => ({
  type: JOBEDIT_UPDATE_FAILED,
});

export const updateAfterEditJob = (jobid, ownProps) => {
  return (dispatch, getState) => {
    // console.log( jobid )
    // console.log( getState().announcement )
    return axios
      .put(
        process.env.REACT_APP_API_URL + 'announcement/job-edit/' + jobid,
        getState().announcement
      )
      .then((updateStatus) => {
        if (updateStatus.data.updateJobEditSuccess) {
          dispatch(jobEditUpdateSuccess());
          ownProps.history.push({ pathname: '/' });
        } else {
          dispatch(jobEditUpdateFailed());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
