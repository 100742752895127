import axios from 'axios'
import sweetAlert from 'sweetalert'

export const UNPUBLISH_SUCCESS = 'UNPUBLISH_SUCCESS';
export const UNPUBLISH_FAILED = 'UNPUBLISH_FAILED';

export const unpublishSuccess = () => ({
    type: UNPUBLISH_SUCCESS
});

export const unpublishFailed = () => ({
    type: UNPUBLISH_FAILED
});

export const unpublishJob = ( unpublishList, ownProps ) => {
    return ( dispatch ) => {
        return sweetAlert({
            title: "Are you sure?",
            text: "These job(s) will be unpublished and not shown on the home page.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then( toUnpublish => {
            if( toUnpublish ){
                axios.post( process.env.REACT_APP_API_URL + 'announcement/unpublishJob', unpublishList)
                    .then( ({ data }) => {
                        console.log( data );
                        if( data.unpublishSuccess ){
                            sweetAlert("Poof! Your job(s) has been unpublished!", {
                                icon: "success",
                                closeOnClickOutside: false,
                            });

                            dispatch( unpublishSuccess() );
                        }else{
                            sweetAlert("Your job(s) is failed to unpublish.", {
                                icon: "error",
                                closeOnClickOutside: false,
                            });

                            dispatch( unpublishFailed() )
                        }
                    } );
            }
        } )
    }
}