import React from 'react'
import NavBar from '../../component/NavBar';
import Footer from '../../component/Footer';
import ReactGA from 'react-ga';

class ApplyJob extends React.Component {

    constructor(){
        super();

        if( process.env.NODE_ENV === 'production' ){
            ReactGA.initialize('UA-35234591-4');
            ReactGA.pageview(window.location.pathname);
        }
    }
    
    render(){
        return(
            <div className="login-view">
                <NavBar />
                <div style={{ minHeight: "70vh", padding:"15vh 10%" }}>
                    <h1 style={{ color:"rgba(192, 32, 45, 0.904)" }}>Apply jobs through us, increase your chance to be accepted</h1>
                    <p>ពួកយើងផ្តល់នូវសេវាកម្មដ៏មានទំនុកចិត្តសម្រាប់ ក្រុមហ៊ុន ក៏ដូចជា បុគ្គលិក ដើម្បីអោយភាគីទាំងពីរបានជួបគ្នា។</p>
                    <p>
                        <b>For the job seeker</b>, you may submit your CV to us via <a href="mailto:itjob.kagea@gmail.com">itjob.kagea@gmail.com</a> or call to (855)-10-844-016 for more information.
                    </p>
                    <p>
                        <b>For the Company</b>, we are ready to serve you with quality IT candidates. We aims to be the best IT recruitement agency in Cambodia. 
                        We are growing our IT people connections from day-to-day. Please do not hesitate to contact us via <a href="mailto:itjob.kagea@gmail.com">itjob.kagea@gmail.com</a> or (855)-10-844-016.
                    </p>
                    <p>
                        We are trying to change the mindset of recruitement. Candidate should not be attracted by money, but a great working environment would do. We will have the client before the candidates.
                        Each post we have published are real. If your CV makes the cut, you will be contacted for interview.
                    </p>
                </div>
                <Footer />
            </div>
        );
    }

}

export default ApplyJob;