import React from 'react';
import { Editor } from 'slate-react';
import { Value } from 'slate';
import initialValue from './intialValueSlateEditor.json';
import Modal from 'react-awesome-modal';
import DraggableUpload from './draggableUpload';
// import { ReCaptcha } from 'react-recaptcha-google'

class MyAnnouncement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      firstname: '',
      lastname: '',
      email: '',
      captchaToken: '',

      isValidFname: false,
      emptyFname: false,
      isValidLname: false,
      emptyLname: false,
      applyValid: false,
      emptyEmail: false,
      isValidEmail: false,
      isVerified: false,

      fileUploadStatus: false,
      loadedFile: {},
    };

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.fieldApplyValidation = this.fieldApplyValidation.bind(this);
    this.applyValidation = this.applyValidation.bind(this);
    this.callUploadBack = this.callUploadBack.bind(this);
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }
  verifyCallback(recaptchaToken) {
    this.setState({ captchaToken: recaptchaToken, isVerified: true });
  }

  togglePopup = () => {
    this.setState({ visible: !this.state.visible });
  };

  callUploadBack = (fileUpload, fileStatus) => {
    this.setState({ loadedFile: fileUpload, fileUploadStatus: fileStatus });
  };

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  fieldApplyValidation = (e) => {
    let input = e.target.name;
    let value = e.target.value;
    switch (input) {
      case 'email': {
        let isEmptyEmail = value.length === 0 ? false : true;
        // eslint-disable-next-line
        let isValidEmailTemp =
          value.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) !== null
            ? true
            : false;
        this.setState({
          email: value,
          emptyEmail: isEmptyEmail,
          isValidEmail: isValidEmailTemp,
        });
        break;
      }
      case 'firstname': {
        let isEmptyFname = value.length === 0 ? false : true;
        // eslint-disable-next-line
        let isValidFnameTemp =
          value.match(/^[a-z ,.'-]+$/i) !== null ? true : false;
        this.setState({
          firstname: value,
          emptyFname: isEmptyFname,
          isValidFname: isValidFnameTemp,
        });
        break;
      }
      case 'lastname': {
        let isEmptyLname = value.length === 0 ? false : true;
        // eslint-disable-next-line
        let isValidLnameTemp =
          value.match(/^[a-z ,.'-]+$/i) !== null ? true : false;
        this.setState({
          lastname: value,
          emptyLname: isEmptyLname,
          isValidLname: isValidLnameTemp,
        });
        break;
      }
      default:
        break;
    }
  };

  applyValidation(event) {
    event.preventDefault();
    // this.captchaDemo.reset();
    if (
      this.state.isValidEmail &&
      this.state.isValidFname &&
      this.state.isValidLname &&
      this.state.fileUploadStatus &&
      this.state.isVerified
    ) {
      this.props.callFormBack(this.state);
      // this.closeModal();
    }
    this.setState({ applyValid: true });
  }

  // componentWillReceiveProps(nextProps) {
  //     // Any time props.email changes, update state.
  //     if (nextProps.upload.isUploaded !== this.props.upload.isUploaded) {
  //         if(nextProps.upload.isUploaded){
  //             this.setState({visible:false});
  //         }
  //         else{
  //             this.setState({visible:true});
  //         }
  //     }
  // }

  render() {
    return (
      <div>
        <div className="button-apply-job">
          {this.props.job.isLoaded ? (
            <input
              type="button"
              value="Apply Now"
              className="apply-job"
              onClick={this.togglePopup}
            />
          ) : (
            ''
          )}
        </div>
        <br />
        <div className="salary-range">
          {this.props.job.isLoaded
            ? 'Salary Range: ' +
              this.props.job.salaryFrom +
              ' - ' +
              this.props.job.salaryTo +
              ' USD '
            : ''}
        </div>
        <br />
        <Editor
          value={Value.fromJSON(
            this.props.job.isLoaded
              ? this.props.job.jobDescription
              : initialValue
          )}
          renderMark={this.renderMark}
          renderNode={this.renderNode}
          readOnly
        />
        <br />
        <Editor
          value={Value.fromJSON(
            this.props.job.isLoaded
              ? this.props.job.jobRequirement
              : initialValue
          )}
          renderMark={this.renderMark}
          renderNode={this.renderNode}
          readOnly
        />
        <div className="button-apply-job">
          {this.props.job.isLoaded ? (
            <input
              type="button"
              value="Apply Now"
              className="apply-job"
              onClick={this.togglePopup}
            />
          ) : (
            ''
          )}
        </div>
        <Modal
          visible={this.props.upload.isUploaded ? false : this.state.visible}
          width="500"
          onClickAway={() => this.closeModal()}
        >
          <span className="popup-title">Apply for a job</span>
          <form
            className="popup-inner"
            onSubmit={this.applyValidation}
            method="post"
            encType="multipart/form-data"
            action="/upload"
          >
            <div className="popup-form">
              <span style={{ fontWeight: 'bold' }}>Email</span>
              <input
                type="text"
                placeholder="Email"
                name="email"
                style={
                  !this.state.applyValid
                    ? { border: '' }
                    : this.state.isValidEmail
                    ? { border: '' }
                    : { border: '1px solid red' }
                }
                onChange={this.fieldApplyValidation}
              />
              <p style={message}>
                {!this.state.applyValid
                  ? ''
                  : !this.state.emptyEmail
                  ? '*Please fill out this field'
                  : !this.state.isValidEmail && this.state.emptyEmail
                  ? '*Please enter valid email'
                  : ''}
              </p>
              <div className="row">
                <div className="col">
                  <span style={{ fontWeight: 'bold' }}>First Name</span>
                  <input
                    className="textbox-left"
                    type="text"
                    placeholder="First Name"
                    style={
                      !this.state.applyValid
                        ? { border: '' }
                        : this.state.isValidFname
                        ? { border: '' }
                        : { border: '1px solid red' }
                    }
                    onChange={this.fieldApplyValidation}
                    name="firstname"
                  />
                  <p style={message}>
                    {!this.state.applyValid
                      ? ''
                      : !this.state.emptyFname
                      ? '*Please fill out this field'
                      : ''}
                  </p>
                </div>
                <div className="col">
                  <span style={{ fontWeight: 'bold' }}>Last Name</span>
                  <input
                    className="textbox-right"
                    type="text"
                    placeholder="Last Name"
                    style={
                      !this.state.applyValid
                        ? { border: '' }
                        : this.state.isValidLname
                        ? { border: '' }
                        : { border: '1px solid red' }
                    }
                    onChange={this.fieldApplyValidation}
                    name="lastname"
                  />
                  <p style={message}>
                    {!this.state.applyValid
                      ? ''
                      : !this.state.emptyLname
                      ? '*Please fill out this field'
                      : ''}
                  </p>
                </div>
              </div>
              <span
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Upload your CV below:{' '}
              </span>
              <DraggableUpload callUploadBack={this.callUploadBack} />
              <span style={message} className="upload-cv-file">
                {!this.state.applyValid
                  ? ''
                  : !this.state.fileUploadStatus
                  ? '*Please upload your CV file'
                  : ''}
              </span>
              <div className="recaptcha">
                <div>
                  {/* <ReCaptcha 
                                        ref={ el => { this.captchaDemo = el; }}
                                        size="normal"
                                        data-theme="dark"            
                                        render="explicit"
                                        sitekey={ process.env.REACT_APP_SITE_KEY }
                                        onloadCallback = { this.onLoadRecaptcha }
                                        verifyCallback = { this.verifyCallback }
                                    /> */}
                  <p style={message}>
                    {!this.state.applyValid
                      ? ''
                      : this.state.isVerified
                      ? ''
                      : 'Please verify this*'}
                  </p>
                </div>
              </div>
            </div>
            <div className="popup-button">
              <input
                type="button"
                onClick={this.togglePopup}
                value="Close"
                className="popup-close"
              />
              <input type="submit" value="Submit" className="popup-submit" />
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  /**
   * Render a Slate node.
   * @param {Object} props
   * @return {Element}
   */

  renderNode = (props, editor, next) => {
    const { attributes, children, node } = props;
    switch (node.type) {
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>;
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      default:
        return next();
    }
  };

  /**
   * Render a Slate mark.
   * @param {Object} props
   * @return {Element}
   */

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props;
    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'code':
        return <code {...attributes}>{children}</code>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underlined':
        return <u {...attributes}>{children}</u>;
      default:
        return next();
    }
  };
}

const message = {
  color: 'red',
  fontSize: '13px',
};

export default MyAnnouncement;
