import axios from 'axios';
import swal from 'sweetalert';

export const DELETE_SUCCESS = 'DELETE_SUCCESS'
export const DELETE_FAILED  = 'DELETE_FAILED'


export const deleteSuccess = () => ({
    type: DELETE_SUCCESS
}) 
export const deleteFailed = () => ({
    type: DELETE_FAILED
})

export const deleteJob = ( deleteList, ownProps ) => {
    return ( dispatch ) => {
        // console.log( deleteList )
        return  swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this job(s)!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then(( willDelete ) => {
                    if ( willDelete ) {
                        axios.post( process.env.REACT_APP_API_URL + 'announcement/deleteJob', deleteList)
                            .then( deleteStatus =>{
                                console.log( deleteStatus )
                                if( deleteStatus.data.deleteSuccess ){
                                    swal("Poof! Your job(s) has been deleted!", {
                                        icon: "success",
                                        closeOnClickOutside: false,
                                    });
                                    dispatch( deleteSuccess() )
                                }else{
                                    swal("Your job(s)'s deletion is failed.", {
                                        icon: "error",
                                        closeOnClickOutside: false,
                                    });
                                    dispatch( deleteFailed() )
                                }
                            }).catch( error => {
                                console.log( error );
                            });
                    } else{
                        swal("Your job(s) has not been deleted")
                    } 
                })
    }
}