export default class Auth {
    
    isAuthenticated = () => {
        const expAt = JSON.parse( localStorage.getItem( 'expire_at' ) );
        return Math.round( Date.now() / 1000 ) < expAt;
    }

    logout = () => {
        localStorage.clear();
    }

}

export const instance = new Auth();