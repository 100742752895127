import React from 'react'
import NavBar from '../../component/NavBar';
import Welcome from '../../component/Welcome';
import Footer from '../../component/Footer';

class WelcomeView extends React.Component {

    render(){
        return(
            <div className="login-view">
                <NavBar />
                <Welcome />
                <Footer />
            </div>
        );
    }

}

export default WelcomeView;