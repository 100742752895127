import React from 'react'
import NavBar from '../../component/NavBar';
import Article from '../../component/Article'

import './index.css'

class ArticleView extends React.Component {

    render(){
        return (
            <div className="article">
                <NavBar />
                <Article />
            </div>
        )
    }

}

export default ArticleView;